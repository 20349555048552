import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { AccountCircleOutlined, DashboardOutlined } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Button,
  Link,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import MenuList from "./MenuList";
import "./Menu.css";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBarTop = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

var sidebar = 0;
export default function Sidebar() {
  const servidor = ["Adicionar", "Pesquisar"];
  const aeronave = ["Adicionar", "Abastacimentos", "Pesquisar"];
  const ocorrencia = ["Registrar", "Pesquisar"];
  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.removeItem("token");
    localStorage.removeItem("nome");
    localStorage.removeItem("id");
    navigate("/");
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = (e) => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarTop
        position="fixed"
        sx={{
          background:
            "#104283 linear-gradient(to left, #104283 , #072650 )  no-repeat",
        }}
        open={open}
      >
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, color: "#DDDBDB" }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ ml: 3, fontWeight: "normal" }} className="ciopaer">
            <Typography variant="h6">
              Centro Integrado de Operações Aéreas -
            </Typography>
          </Box>
          <Box sx={{ ml: 1, fontWeight: "normal" }}>
            <Typography variant="h6">CIOPAER</Typography>
          </Box>
          <Box sx={{ ml: 3, fontWeight: "normal" }}>
            <Button
              sx={{ position: "fixed", right: "5px", marginTop: "-18px" }}
              onClick={logout}
              color="inherit"
            >
              <LogoutIcon />
            </Button>
          </Box>
        </Toolbar>
      </AppBarTop>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background:
              "#104283 linear-gradient(to top, #104283 , #072650 ) no-repeat",
          },
        }}
        variant="persistent"
        open={open}
      >
        <DrawerHeader sx={{ display: "flex", justifyContent: "center" }}>
          <img src="../imagens/harpia2.png" width={180} />
        </DrawerHeader>
        <Divider sx={{ marginX: "10px", mb: 3, background: "#DDDBDB" }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton sx={{ p: 0, px: 4 }}>
              <ListItemIcon sx={{ color: "#DDDBDB" }}>
                <DashboardOutlined />
              </ListItemIcon>
              <Box>
                <Link href="/dashboard" sx={{ textDecoration: "none" }}>
                  {" "}
                  <h3 style={{ color: "#DDDBDB", fontWeight: "500" }}>
                    {" "}
                    Dashboard
                  </h3>
                </Link>
              </Box>
            </ListItemButton>
          </ListItem>
          <MenuList namelist="Servidor" list={servidor} />
          <MenuList namelist="Aeronave" list={aeronave} />
          <MenuList namelist="Ocorrência" list={ocorrencia} />
        </List>
        <ListItem sx={{ p: 0, position: "absolute", bottom: 0 }} disablePadding>
          <ListItemButton sx={{ p: 0, px: 4 }}>
            <ListItemIcon sx={{ color: "#DDDBDB" }}>
              <AccountCircleOutlined />
            </ListItemIcon>
            <h4 style={{ color: "#DDDBDB", fontWeight: "500" }}>
              {localStorage.nome}
            </h4>
          </ListItemButton>
        </ListItem>
      </Drawer>
      <Main open={open} sx={{ p: 0, mb: 5 }}>
        <DrawerHeader />
        <Outlet />
        <Box className="footer">
          <Typography sx={{ mt: 1 }}>
            Secretaria de Justiça e Segurança Pública
          </Typography>
        </Box>
      </Main>
    </Box>
  );
}
