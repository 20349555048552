import Box from "@mui/material/Box";
import InputMask from "react-input-mask";
import ListSelect from "../../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography, Link, Card } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../../../Pagination";
import EditIcon from "@mui/icons-material/Edit";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Abastecimentos from "../../../Relatorio/Abastecimentos";
import { URL_API } from "../../../../constants";
import {
  FormatarDataBanco,
  BuscarAeronaves,
  BuscarEmpresa,
} from "../../../../functions";
const PesquisarAbastecimento = () => {
  const [aeronaves, setAeronaves] = useState([]);

  const [empresas, setEmpresas] = useState([]);

  const [abastecimentos, setAbastecimentos] = useState([]);

  const [valuesInput, setValuesInput] = useState([]);

  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };
  useEffect(() => {
    async function fetchData() {
      setAeronaves(await BuscarAeronaves(localStorage.token));
      setEmpresas(await BuscarEmpresa(localStorage.token));
    }

    fetchData();
  }, []);
  //Buscar Fornecedores

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/pesquisarabastecimento`, {
        token: localStorage.token,
        dados_abastecimentos: valuesInput,
      })
      .then((response) => {
        if (response.data.status === "warning") {
          toast(response.data.message, {
            type: response.data.status,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setAbastecimentos([]);
        } else {
          setAbastecimentos(response.data);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const PER_PAGE = 8;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(
    Math.ceil(abastecimentos.length / PER_PAGE)
  );

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    setCount(Math.ceil(abastecimentos.length / PER_PAGE));
  }, [abastecimentos]);
  const _DATA = usePagination(abastecimentos, PER_PAGE);
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Grid container>
          <Grid xs={12} md={6}>
            <Typography
              sx={{ m: 2, color: "#072650", fontWeight: "700" }}
              variant="h5"
            >
              Abastecimento
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
            <Link href="/registrarabastecimento">
              <Button
                variant="contained"
                sx={{ backgroundColor: "#0D6EFD", borderRadius: "10px" }}
              >
                <AddIcon sx={{}} />
                Registrar
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2, my: 3 }}>
            <InputMask mask="99/99/9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="data_inicial"
                  name="data_inicial"
                  label="Data Inicial"
                  variant="outlined"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2, my: 3 }}>
            <InputMask mask="99/99/9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  fullWidth
                  id="data_final"
                  name="data_final"
                  label="Data Final"
                  variant="outlined"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Aeronave"
              list={aeronaves}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.aeronave : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  aeronave: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Empresa"
              list={empresas}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.empresa : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  empresa: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ backgroundColor: "#0D6EFD", borderRadius: "10px", m: 1 }}
              >
                <SearchIcon sx={{ mx: 1 }} />
                Buscar
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "end",
            }}
          >
            {abastecimentos.length > 0 ? (
              <PDFDownloadLink
                document={<Abastecimentos dados={abastecimentos} />}
                fileName="Abastecimentos.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="outlined" sx={{ mx: 2 }}>
                      <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                    </Button>
                  )
                }
              </PDFDownloadLink>
            ) : (
              ""
            )}
          </Grid>
          <Grid container>
            {_DATA.currentData().map((item) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{ px: 2, my: 2, minWidth: "300px" }}
                >
                  <Card fullWidth sx={{ p: 1, minHeight: "200px" }}>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Aeronave:</h4>
                      {item.aeronave.apelido_aeronave}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Empresa:</h4>
                      {item.empresa.descricao_empresa}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Combustível:</h4>
                      {item.combustivei.descricao_combustivel}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Litros:</h4>
                      {item.litros_abastecimento}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Data:</h4>
                      {FormatarDataBanco(item.data_abastecimento)}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Local:</h4>
                      {item.local_abastecimento}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Recibo:</h4>
                      {item.recibo_abastecimento}
                    </Typography>
                    <Box fullWidth sx={{ textAlign: "right", pr: 1 }}>
                      <Link
                        variant="contained"
                        sx={{ mt: 1 }}
                        href={"editarabastecimento/" + item.id}
                      >
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{ m: 1 }}
                        >
                          <EditIcon />
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                px: 2,
                my: 2,
                justifySelf: "center",
              }}
            >
              {count > 0 ? (
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  onChange={handleChangePage}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>

        <ToastContainer />
      </Box>
    </form>
  );
};
export default PesquisarAbastecimento;
