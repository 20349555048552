import Box from "@mui/material/Box";
import ListSelect from "../ComponentsFormulario/ListSelect";
import { Grid, Button, Typography, Card, Link } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import usePagination from "../../../Pagination";
import Pagination from "@mui/material/Pagination";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Servidores from "../../Relatorio/Servidores";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { URL_API, funcao_servidor, origem_servidor } from "../../../constants";
import CachedIcon from "@mui/icons-material/Cached";

const PesquisarServidor = () => {
  const [servidores, setServidores] = useState([]);
  const [valuesInput, setValuesInput] = useState([]);
  const reset = () => {
    setValuesInput([]);
  };
  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarservidor`, {
        token: localStorage.token,
        dados_servidores: valuesInput,
      })
      .then(function (response) {
        if (response.data.status === "warning") {
          toast(response.data.message, {
            type: response.data.status,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setServidores([]);
        } else {
          setServidores(response.data);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [valuesInput]);

  const PER_PAGE = 8;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(Math.ceil(servidores.length / PER_PAGE));

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    setCount(Math.ceil(servidores.length / PER_PAGE));
  }, [servidores]);
  const _DATA = usePagination(servidores, PER_PAGE);

  return (
    <form>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography sx={{ color: "#072650", fontWeight: "700" }} variant="h5">
          Pesquisar Servidor
          <Box sx={{ textAlign: "right", mt: -4 }}>
            <Button
              type="submit"
              variant="contained"
              onClick={reset}
              sx={{
                backgroundColor: "#0D6EFD",
                borderRadius: "10px",
              }}
            >
              <CachedIcon />
            </Button>
          </Box>
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Origem"
              list={origem_servidor}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.origem : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  origem: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Função"
              list={funcao_servidor}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.funcao : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  funcao: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Situação"
              list={["Ativo", "Inativo"]}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.situacao : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  situacao: valor,
                }))
              }
            />
          </Grid>
          {/* PDF */}
          <Grid
            container
            sx={{
              justifyContent: "end",
            }}
          >
            {_DATA.currentData().length > 0 ? (
              <PDFDownloadLink
                document={<Servidores dados={_DATA.currentData()} />}
                fileName="Servidores.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="outlined" sx={{ mx: 2 }}>
                      <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                    </Button>
                  )
                }
              </PDFDownloadLink>
            ) : (
              ""
            )}
          </Grid>
          {/* LISTAR USUÁRIOS */}
          <Grid container>
            {_DATA.currentData().map((item) => {
              return (
                <Grid item xs={12} sm={12} md={4} sx={{ px: 2, my: 2 }}>
                  <Card
                    fullWidth
                    sx={{
                      p: 1,
                      minHeight: "260px",
                      border: "1px solid #d3d3d3",
                      minWidth: "250px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Nome:</h4>{" "}
                      {item.nome_completo_servidor}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Origem:</h4>{" "}
                      {item.origem_servidor}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Função:</h4>{" "}
                      {item.funcao_servidor}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Status:</h4>{" "}
                      {item.status_servidor}
                    </Typography>
                    <Box
                      fullWidth
                      sx={{
                        textAlign: "right",
                        pr: 1,
                        margin:
                          item.nome_completo_servidor.length > 50
                            ? "-40px 0px"
                            : "0px",
                      }}
                    >
                      <Link
                        variant="contained"
                        href={"visualizarservidor/" + item.id}
                      >
                        <Button variant="contained" sx={{ m: 1 }}>
                          <VisibilityOutlinedIcon />
                        </Button>
                      </Link>
                      <Link
                        variant="contained"
                        href={"editarservidor/" + item.id}
                      >
                        <Button
                          color="warning"
                          variant="contained"
                          sx={{ m: 1 }}
                        >
                          <EditIcon />
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {/* PAGINAÇÃO */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              px: 2,
              my: 2,
              justifySelf: "center",
            }}
          >
            {count > 0 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={handleChangePage}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </form>
  );
};
export default PesquisarServidor;
