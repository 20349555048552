import {
  Box,
  Grid,
  CardContent,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import LineChart from "./Charts/Line";
import { DataGrid } from "@mui/x-data-grid";
import EvStationIcon from "@mui/icons-material/EvStation";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import BarChart from "./Charts/Bar";
import { BuscarDados } from "../../functions";
import "./Dashboard.css";
import CardComponent from "../../components/Card";
import { URL_API } from "../../constants";
import axios from "axios";


const DashboardPage = () => {
  const [countdados, setCountDados] = useState([]);
  const [data, setData] = useState({});

  const columnsServidores = [
    {
      field: "nome_completo_servidor",
      headerName: "Nome do Servidor",
      width: 300,
    },
    { field: "funcao_servidor", headerName: "Função", width: 150 },
    { field: "horas_voo_servidor", headerName: "Horas de Vôo", width: 200 },
  ];
  const columnsTipo = [
    {
      field: "tipo_ocorrencia",
      headerName: "Ocorrência",
      width: 130,
    },
    { field: "horas_de_voo", headerName: "Horas de Vôo", width: 200 },
  ];
  const columnsAeronaves = [
    { field: "apelido_aeronave", headerName: "Aeronave", width: 150 },
    { field: "horas_voo_aeronave", headerName: "Horas de Vôo", width: 200 },
  ];
  useEffect(() => {
    axios
      .post(`${URL_API}/dashboard`, {
        dados: true,
      })
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  return (
    <Box
      sx={{
        background: "white",
        p: 2,
        m: 2,
        borderRadius: "10px",
        boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Box sx={{ py: 1 }}>
        <Typography
          sx={{
            color: "#072650",
            fontWeight: "700",
          }}
          variant="h5"
        >
          Dashboard
        </Typography>
        <Divider />
      </Box>
      <Grid container spacing={0.5} my={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0.5}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
                Servidores
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.Piloto ? data.Piloto : ""}
                Color={"#1E90FF"}
                Title={"Pilotos"}
                Icon={
                  <PersonIcon sx={{ fontSize: 65, color: "white", mt: 2 }} />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.Copiloto ? data.Copiloto : ""}
                Color={"#3CB371"}
                Title={"Copilotos"}
                Icon={
                  <PersonIcon sx={{ fontSize: 65, color: "white", mt: 2 }} />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.Total_pilotos ? data.Total_pilotos : ""}
                Color={"#FF8C00"}
                Title={"Total"}
                Icon={
                  <PersonIcon sx={{ fontSize: 65, color: "white", mt: 2 }} />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0.5} sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
                Aeronaves
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={
                  data.aeronaves_cadastradas ? data.aeronaves_cadastradas : ""
                }
                Color={"#1E90FF"}
                Title={"Cadastradas"}
                Icon={
                  <AirplanemodeActiveIcon
                    sx={{ fontSize: 65, color: "white", mt: 2 }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.aeronaves_ativas ? data.aeronaves_ativas : ""}
                Color={"#3CB371"}
                Title={"Ativas"}
                Icon={
                  <AirplanemodeActiveIcon
                    sx={{ fontSize: 65, color: "white", mt: 2 }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0.5} my={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0.5} sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
                Ocorrências
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.ocorrencias_mes ? data.ocorrencias_mes : 0}
                Color={"#1E90FF"}
                Title={"Ocorrências do Mês"}
                Icon={
                  <NotificationImportantIcon
                    sx={{ fontSize: 65, color: "white", mt: 2 }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.ocorrencias_ano ? data.ocorrencias_ano : 0}
                Color={"#3CB371"}
                Title={"Ocorrências do Ano"}
                Icon={
                  <NotificationImportantIcon
                    sx={{ fontSize: 65, color: "white", mt: 2 }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0.5} sx={{ justifyContent: "center" }}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
                Abastecimentos
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.Abastecimento_mes ? data.Abastecimento_mes : 0}
                Color={"#1E90FF"}
                Title={"Abastecimento do Mês"}
                Icon={
                  <NotificationImportantIcon
                    sx={{ fontSize: 65, color: "white", mt: 2 }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardComponent
                Data={data.Abastecimento_ano ? data.Abastecimento_ano : 0}
                Color={"#3CB371"}
                Title={"Abastecimento do Ano"}
                Icon={
                  <NotificationImportantIcon
                    sx={{ fontSize: 65, color: "white", mt: 2 }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box className="graficos">
        <Grid container>
          <Grid item xs={12} md={6} p={5}>
            <BarChart
              nome_grafico={"Ocorrência registradas por Município no Mês"}
              dataOcorrencias={
                data.ocorrencias_municipios_mes
                  ? data.ocorrencias_municipios_mes.cidades
                  : []
              }
              dataRegistros={
                data.ocorrencias_municipios_mes
                  ? data.ocorrencias_municipios_mes.dados
                  : []
              }
              widChart={350}
            />
          </Grid>
          <Grid item xs={12} md={6} p={5}>
            <BarChart
              nome_grafico={"Naturezas de ocorrência registradas no Mês"}
              dataOcorrencias={
                data.natureza_ocorrencia_mensal
                  ? data.natureza_ocorrencia_mensal.natureza
                  : []
              }
              dataRegistros={
                data.natureza_ocorrencia_mensal
                  ? data.natureza_ocorrencia_mensal.dados
                  : []
              }
              widChart={350}
            />
          </Grid>
        </Grid>

        <Divider />
        <Grid container>
          <Grid item xs={12} md={6} p={5}>
            <BarChart
              nome_grafico={"Ocorrência registradas por Município no Ano"}
              dataOcorrencias={
                data.ocorrencias_municipios_ano
                  ? data.ocorrencias_municipios_ano.cidades
                  : []
              }
              dataRegistros={
                data.ocorrencias_municipios_ano
                  ? data.ocorrencias_municipios_ano.dados
                  : []
              }
              widChart={350}
            />
          </Grid>
          <Grid item xs={12} md={6} p={5}>
            <BarChart
              nome_grafico={"Tipo de Ocorrências registradas no Ano"}
              dataOcorrencias={
                data.ocorrencias_tipo_ano ? data.ocorrencias_tipo_ano.tipo : []
              }
              dataRegistros={
                data.ocorrencias_tipo_ano ? data.ocorrencias_tipo_ano.dados : []
              }
              widChart={350}
            />
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box className="" sx={{ my: 5, m: 3 }}>
        <Grid container>
          <Grid item xs={12} md={12} p={1} sx={{ textAlign: "center" }}>
            <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
              Comparativo Anual
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            p={1}
            sx={{ border: "1px dashed #E0E0E0", borderRadius: "15px" }}
          >
            <LineChart
              dataRegistros={data.comparativo_ano ? data.comparativo_ano : {}}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box className="">
        <Grid container>
          <Grid item md={12}>
            <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
              Horas de Vôo
            </Typography>
          </Grid>
          <Grid md={6} xs={12} p={1}>
            <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
              Servidores
            </Typography>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={
                  data.horas_voo_servidores ? data.horas_voo_servidores : []
                }
                columns={columnsServidores}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
          </Grid>
          <Grid md={3} xs={12} p={1}>
            <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
              Aeronaves
            </Typography>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={data.horas_voo_aeronaves ? data.horas_voo_aeronaves : []}
                columns={columnsAeronaves}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} p={1}>
            <Typography sx={{ fontWeight: 700, m: 1, textAlign: "center" }}>
              Tipo de Ocorrências Mensal
            </Typography>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={
                  data.ocorrencias_tipo_mes ? data.ocorrencias_tipo_mes : []
                }
                columns={columnsTipo}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
};
export default DashboardPage;
