import Box from "@mui/material/Box";
import InputMask from "react-input-mask";
import ListSelect from "../../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography } from "@mui/material";
import SalveIcon from "@mui/icons-material/UploadFile";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_API } from "../../../../constants";

const EditarAeronave = () => {
  const tipo_uso = ["Aeromédico/Bombeiro", "Policial", "VIP"];
  const status_aeronave = ["Ativa", "Inativa"];
  const [valueTipo, setValueTipo] = useState();
  const [valueSituacao, setValueSituacao] = useState();
  const [valuesInput, setValuesInput] = useState([]);
  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  const { id } = useParams();
  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisaraeronave`, {
        id: id,
        token: localStorage.token,
      })
      .then(function (response) {
        setValuesInput(response.data);
        setValueTipo(response.data.tipo_uso_aeronave);
        setValueSituacao(response.data.status_aeronave);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${URL_API}/editaraeronave`, {
        token: localStorage.token,
        id: id,
        prefixo: valuesInput.prefixo_aeronave,
        apelido: valuesInput.apelido_aeronave,
        fabricante: valuesInput.fabricante_aeronave,
        modelo: valuesInput.modelo_aeronave,
        numero_serie: valuesInput.numero_serie_aeronave,
        ano_fabricacao: valuesInput.ano_fabricacao_aeronave,
        horas_iniciais: valuesInput.horas_iniciais_aeronave,
        horas_mes: valuesInput.horas_mes_aeronave,
        tipo_uso: valueTipo,
        status: valueSituacao,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Nova Aeronave
        </Typography>

        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Tipo Uso"
              list={tipo_uso}
              up={valueTipo}
              valor={valueTipo}
              handleChange={(valor) => setValueTipo(valor)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Status"
              list={status_aeronave}
              up={valueSituacao}
              valor={valueSituacao}
              handleChange={(valor) => setValueSituacao(valor)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="aa-aaa"
              defaultValue=" "
              value={valuesInput.prefixo_aeronave}
              onChange={handleaddValues}
            >
              {() => (
                <TextField
                  fullWidth
                  id="prefixo_aeronave"
                  name="prefixo_aeronave"
                  label="Prefixo"
                  variant="standard"
                  required
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              fullWidth
              id="apelido_aeronave"
              name="apelido_aeronave"
              label="Apelido"
              variant="standard"
              defaultValue=" "
              value={valuesInput.apelido_aeronave}
              onChange={handleaddValues}
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              fullWidth
              id="fabricante_aeronave"
              name="fabricante_aeronave"
              label="Fabricante"
              variant="standard"
              defaultValue=" "
              value={valuesInput.fabricante_aeronave}
              onChange={handleaddValues}
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              fullWidth
              id="modelo_aeronave"
              name="modelo_aeronave"
              label="Modelo"
              variant="standard"
              defaultValue=" "
              value={valuesInput.modelo_aeronave}
              onChange={handleaddValues}
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              fullWidth
              id="numero_serie_aeronave"
              name="numero_serie_aeronave"
              label="Nº série"
              variant="standard"
              defaultValue=" "
              value={valuesInput.numero_serie_aeronave}
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="9999"
              defaultValue=" "
              value={valuesInput.ano_fabricacao_aeronave}
              onChange={handleaddValues}
            >
              {() => (
                <TextField
                  fullWidth
                  id="ano_fabricacao_aeronave"
                  name="ano_fabricacao_aeronave"
                  label="Ano Fabricação"
                  variant="standard"
                  required
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              fullWidth
              id="horas_iniciais_aeronave"
              name="horas_iniciais_aeronave"
              label="Horas Inciais"
              variant="standard"
              defaultValue=" "
              disabled
              value={valuesInput.horas_iniciais_aeronave}
              onChange={handleaddValues}
            />
          </Grid>

          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              fullWidth
              id="horas_mes_aeronave"
              name="horas_mes_aeronave"
              label="Horas Mês"
              variant="standard"
              disabled
              defaultValue=" "
              value={valuesInput.hora_mes_aeronave}
              onChange={handleaddValues}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <SalveIcon sx={{ mx: 1 }} />
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <ToastContainer />
      </Box>
    </form>
  );
};
export default EditarAeronave;
