import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import InputMask from "react-input-mask";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { URL_API } from "../../../constants";

const FormularioLogin = () => {
  const [valuesInput, setValuesInput] = useState();

  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  function submit(e) {
    e.preventDefault();
    axios
      .post(`${URL_API}/login`, {
        cpf: valuesInput.cpf_servidor.replace(/[^\d]+/g, ""),
        senha: valuesInput.senha_servidor,
      })
      .then((response) => {
        if (
          response.data.status === "success" &&
          response.data.nome_servidor !== undefined &&
          response.data.token !== undefined &&
          response.data.id !== undefined
        ) {
          localStorage.setItem("nome", response.data.nome_servidor);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("id", response.data.id);
          window.location.href = "/dashboard";
        } else {
          toast(response.data.message, {
            type: response.data.status,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }

  return (
    <form onSubmit={submit}>
      <Box sx={{ marginX: "55px" }}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <InputMask mask="999.999.999-99" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="cpf"
                  name="cpf_servidor"
                  label="CPF"
                  variant="standard"
                  sx={{ width: "100%", marginY: "25px" }}
                  required
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="senha"
              name="senha_servidor"
              type="password"
              label="Senha"
              variant="standard"
              sx={{ width: "100%", marginY: "25px" }}
              required
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#0D6EFD",
                borderRadius: "10px",
                width: "100%",
                marginTop: "25px",
              }}
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </form>
  );
};
export default FormularioLogin;
