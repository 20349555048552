import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import "./FooterLogin.css";
const FooterLogin = () => {
  return (
    <Box className="footer_login">
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={3} md={2} sx={{ textAlign: "right" }}>
          <img
            src="imagens/brasao_gov.png"
            alt="Brasão do Governo"
            width={60}
          />
        </Grid>
        <Grid
          item
          xs={3}
          md={2}
          sx={{ textAlign: "center", paddingTop: "10px" }}
        >
          <img src="imagens/sejusp.png" alt="Brasão da Sejusp" width={50} />
        </Grid>
        <Grid item xs={3} md={2} sx={{ paddingTop: "10px" }}>
          <img
            src="imagens/brasao_ciopaer.png"
            alt="Brasão do Ciopaer"
            width={45}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default FooterLogin;
