import Box from "@mui/material/Box";
import { Grid, TextField, Button, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";

const Policial = ({ onFormChange }) => {
  const [inputValue, setInputValue] = useState([]);

  const handleChange = (event) => {
    setInputValue((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };
  useEffect(() => {
    onFormChange(inputValue);
  }, [inputValue, onFormChange]);

  return (
    <Box sx={{}}>
      <Typography sx={{ m: 2, color: "gray", fontWeight: "600" }} variant="p">
        Itens da Ocorrência
      </Typography>
      <Grid container>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="armas_ocorrencia"
            name="armas_ocorrencia"
            label="Armas"
            type="number"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="carros_ocorrencia"
            name="carros_ocorrencia"
            label="Carros"
            type="number"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="dinheiro_ocorrencia"
            name="dinheiro_ocorrencia"
            label="Dinheiro"
            type="number"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="drogas_ocorrencia"
            name="drogas_ocorrencia"
            label="Drogas"
            type="number"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="motos_ocorrencia"
            name="motos_ocorrencia"
            type="number"
            label="Motos"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="municao_ocorrencia"
            name="municao_ocorrencia"
            label="Munição"
            type="number"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="presos_ocorrencia"
            name="presos_ocorrencia"
            type="number"
            label="Presos"
            variant="standard"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Policial;
