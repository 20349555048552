import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { Grid, TextField, Button, Typography, Link } from "@mui/material";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Aeronave from "../Relatorio/Aeronave";
import { URL_API } from "../../constants";
const VisualizarAeronave = () => {
  const [dadosAeronave, setDadosAeronave] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisaraeronave`, {
        id: id,
      })
      .then(function (response) {
        setDadosAeronave(response.data);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);
  return (
    <Box
      sx={{
        background: "white",
        p: 2,
        m: 3,
        borderRadius: "10px",
        boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "right",
          justifyContent: "end",
        }}
      >
        <PDFDownloadLink
          document={<Aeronave dados={dadosAeronave} />}
          fileName="Aeronave.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <CircularProgress />
            ) : (
              <Button variant="outlined" sx={{ mx: 2 }}>
                <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
              </Button>
            )
          }
        </PDFDownloadLink>
      </Grid>
      <Typography
        sx={{ m: 2, color: "#072650", fontWeight: "700" }}
        variant="h5"
      >
        Aeronave
      </Typography>

      <Grid container>
        <Grid item xs={12} md={6} sx={{ px: 2 }}>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Apelido
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.apelido_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Prefixo
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.prefixo_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Tipo Uso
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.tipo_uso_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Fabricante
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.fabricante_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Modelo
          </Typography>

          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.modelo_aeronave}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ px: 2 }}>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Nº série
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.numero_serie_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Ano Fabricação
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.ano_fabricacao_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Situação
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.status_aeronave}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Horas de Vôo
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosAeronave.horas_voo_aeronave}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} sx={{ px: 2, my: 2 }}>
          <Link href="/pesquisaraeronave" sx={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "gray",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <ReplyOutlinedIcon sx={{ mr: 1 }} />
              Voltar
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VisualizarAeronave;
