import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import brasao_ciopaer from "./brasao_ciopaer.png";
// Create styles
const styles = StyleSheet.create({
  view: {
    flexDirection: "row",
  },
  header: {
    textAlign: "center",
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
  },
  section: {
    flexGrow: 1,
    margin: 2,
  },
  header_image: {
    width: "60px",
    margin: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
const data = new Date();

const new_data = (data) => {
  const data_array = data.split("-");
  const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
  return new_data;
};
const new_horas = (hora) => {
  const hora_array = hora.split(":");
  const new_hora = hora_array[0] + "h:" + hora_array[1] + "min";
  return new_hora;
};

const brasao =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Bras%C3%A3o_do_Acre.svg/1200px-Bras%C3%A3o_do_Acre.svg.png";

// Create Document Component
const Servidor = (props) => (
  <Document>
    <Page size="A4">
      <View
        style={[
          styles.view,
          { borderBottom: "1px solid gray", marginHorizontal: "10px" },
        ]}
      >
        <View style={styles.section}>
          <Image style={styles.header_image} src={brasao} />
        </View>
        <View
          style={[
            styles.section,
            {
              marginTop: "20px",
              marginRight: "180px",
            },
          ]}
        >
          <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
            Estado do Acre
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Secretaria de Estado de Segurança Pública
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Centro Integrado de Operações Aéreas
          </Text>
        </View>
        <View style={[styles.section, { alignItems: "rigth" }]}>
          <Image
            style={[styles.header_image, { alignItems: "rigth" }]}
            src={brasao_ciopaer}
          />
        </View>
      </View>

      <View style={{ textAlign: "center", marginVertical: "15px" }}>
        <Text style={{ fontSize: "16px" }}>FICHA DO SERVIDOR</Text>
      </View>
      <Text
        style={{
          fontSize: "13px",
          padding: "5px",
          margin: "10px",
          borderBottom: "1px solid gray",
        }}
      >
        Dados Pessoais
      </Text>
      <View style={[styles.view, { marginHorizontal: "10px" }]}>
        <View style={styles.section}>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
            }}
          >
            Nome Completo
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.nome_completo_servidor}
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
            }}
          >
            Nome de Guerra
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.nome_guerra_servidor != null
              ? props.dados.nome_guerra_servidor
              : "Não se Aplica"}
          </Text>

          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Data de Nascimento
          </Text>
          <Text
            render={() => new_data(props.dados.data_nascimento_servidor)}
            style={{ fontSize: "13px", padding: "5px" }}
          ></Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
            }}
          >
            Matrícula
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.matricula_servidor != null
              ? props.dados.matricula_servidor
              : "Não se Aplica"}
          </Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Horas de Vôo
          </Text>
          <Text
            render={() => new_horas(props.dados.horas_voo_servidor)}
            style={{ fontSize: "13px", padding: "5px" }}
          ></Text>

          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Código ANAC{" "}
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.codigo_anac_servidor != null
              ? props.dados.codigo_anac_servidor
              : "Não se Aplica"}
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Função
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.funcao_servidor}
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Situação
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.status_servidor}
          </Text>
        </View>
      </View>
      <View style={[styles.view, { marginHorizontal: "10px" }]}>
        <View style={styles.section}>
          <Text
            style={{
              fontSize: "13px",
              padding: "5px",
              marginVertical: "10px",
              borderBottom: "1px solid gray",
            }}
          >
            Documentação
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            CPF
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.cpf_servidor}
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            RG
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.rg_servidor}
          </Text>
        </View>
      </View>
      <View style={[styles.view, { marginHorizontal: "10px" }]}>
        <View style={styles.section}>
          <Text
            style={{
              fontSize: "13px",
              padding: "5px",
              marginVertical: "10px",
              borderBottom: "1px solid gray",
            }}
          >
            Contato
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Email
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.email_servidor}
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Telefone
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.telefone_servidor}
          </Text>
          <Text
            style={{
              backgroundColor: "#e0e0e0",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Endereço
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.endereco_servidor}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default Servidor;
