import { Grid, Card, CardContent, Typography } from "@mui/material";
export default function CardComponent({ Data, Color, Title, Icon }) {
  return (
    <Card sx={{ background: `${Color}`,}}>
      <CardContent>
        <Grid
          container
          sx={{
            textAlign: "center",
            justifyContent: "center",
          }}
          spacing={0}
        >
          <Grid md={3} xs={12}>{Icon}</Grid>
          <Grid md={9}  xs={12}>
            <Typography sx={{ fontSize: 13, color: "white", fontWeight: 700 }}>
              {Title}
            </Typography>
            <Typography sx={{ fontSize: 50, color: "white" }}>
              {Data}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
