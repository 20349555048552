import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import ListSelect from "../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography } from "@mui/material";
import IconSalvar from "@mui/icons-material/UploadFile";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import Resgate from "./TipoOcorrencia/Resgate";
import Policial from "./TipoOcorrencia/Policial";
import {
  URL_API,
  tipo_natureza_ocorrencia,
  tipo_ocorrencia_natureza,
} from "../../../constants";
import {
  FormatarDataBanco,
  BuscarAeronaves,
  BuscarCidades,
  BuscarServidores,
} from "../../../functions";
const operador = [];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditarOcorrencia = () => {
  //id da ocorrência GET
  const { id } = useParams();

  const [valuesInput, setValuesInput] = useState([]);
  const [valuesTipoOcorrencia, setValuesTipoOcorrencia] = useState();

  //Aeronaves
  const [aeronaves, setAeronaves] = useState([]);
  //Comandante
  const [piloto, setPiloto] = useState([]);
  //Copiloto
  const [copiloto, setCopiloto] = useState([]);
  //Cidades
  const [cidades_ac, setCidades_ac] = useState([]);
  //Natureza
  const [natureza, setNatureza] = useState([]);
  //Operadores
  const theme = useTheme();
  const [operadores, setOperadores] = useState([]);
  const [operadoresO, setOperadoresO] = useState([]);
  //Funções

  function getStyles(name, operadores, theme) {
    return {
      fontWeight:
        operadores.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  if (Object.keys(valuesInput).length === 0) {
    axios
      .post(`${URL_API}/pesquisarocorrencia`, {
        id: id,
        token: localStorage.token,
      })
      .then(function (response) {
        setValuesInput(response.data[0]);
        response.data[0].servidores.length > 0
          ? setOperadoresO(response.data[0].servidores.map((data) => data.id))
          : setOperadoresO([]);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }

  useEffect(() => {
    async function fetchData() {
      setAeronaves(await BuscarAeronaves(localStorage.token));
      setPiloto(await BuscarServidores(localStorage.token, "PIC"));
      setCopiloto(await BuscarServidores(localStorage.token, "SIC"));
      setOperadores(await BuscarServidores(localStorage.token, "Operadores"));
      setCidades_ac(await BuscarCidades());
    }

    fetchData();
  }, []);
  useEffect(() => {
    if (valuesInput.hasOwnProperty("tipo_ocorrencia")) {
      setNatureza(tipo_natureza_ocorrencia(valuesInput.tipo_ocorrencia));
    }
  }, [valuesInput]);
  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  //Tipo de Ocorrência (Policial ou Resgate)
  const handleFormChange = (formData) => {
    setValuesTipoOcorrencia(formData);
  };

  //List Checkbox
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOperadoresO(typeof value === "string" ? value.split(",") : value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/atualizarocorrencia`, {
        token: localStorage.token,
        id: id,
        dados_ocorrencia: valuesInput,
        dados_tipo_ocorrencia: valuesTipoOcorrencia,
        id_operadores: operadoresO,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit} enctype="multipart/form-data">
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Editar Ocorrência
        </Typography>
        <Grid container>
          {/* Aeronave */}
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Aeronave *"
              list={aeronaves}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.id_aeronave
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  id_aeronave: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Comandante *"
              list={piloto}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.id_comandante
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  id_comandante: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Copiloto *"
              list={copiloto}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.id_copiloto
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  id_copiloto: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputLabel id="demo-multiple-name-label" sx={{ color: "black" }}>
              Operadores
            </InputLabel>

            <FormControl fullWidth>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={operadoresO}
                onChange={handleChange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
              >
                {operadores.map((name) => (
                  <MenuItem
                    key={name}
                    value={name.id}
                    style={getStyles(name.nome, operadoresO, theme)}
                  >
                    {name.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Base *"
              list={["Rio Branco", "Cruzeiro do Sul"]}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.base_ciopaer
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  base_ciopaer: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Cidade *"
              list={cidades_ac}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.cidade_ocorrencia
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  cidade: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Tipo*"
              list={Object.keys(tipo_ocorrencia_natureza)}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.tipo_ocorrencia
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  tipo_ocorrencia: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Natureza *"
              list={natureza}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.natureza_ocorrencia
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  natureza_ocorrencia: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="99/99/9999"
              onChange={handleaddValues}
              value={
                Object.keys(valuesInput).length > 0
                  ? FormatarDataBanco(valuesInput.data_ocorrencia)
                  : ""
              }
            >
              {() => (
                <TextField
                  id="data_ocorrencia"
                  name="data_ocorrencia"
                  label="Data"
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="99999,9"
              onChange={handleaddValues}
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.horimetro_inicial_ocorrencia
                  : ""
              }
            >
              {() => (
                <TextField
                  id="horimetro_inicial_ocorrencia"
                  name="horimetro_inicial_ocorrencia"
                  label="Horímetro Inicial"
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="99999,9"
              onChange={handleaddValues}
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.horimetro_final_ocorrencia
                  : ""
              }
            >
              {() => (
                <TextField
                  id="horimetro_final_ocorrencia"
                  name="horimetro_final_ocorrencia"
                  label="Horímetro Final"
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={12} sx={{ px: 2, my: 2 }}>
            {valuesInput != undefined &&
            valuesInput.tipo_ocorrencia == "Policial" ? (
              <Policial
                onFormChange={handleFormChange}
                dados_ocorrencia={valuesInput.dados_da_ocorrencia}
              />
            ) : (
              ""
            )}
            {valuesInput != undefined &&
            valuesInput.tipo_ocorrencia == "Resgate" ? (
              <Resgate
                onFormChange={handleFormChange}
                dados_ocorrencia={valuesInput.dados_da_ocorrencia}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={12} sx={{ px: 2, my: 2 }}>
            <TextField
              id="historico_ocorrencia"
              name="historico_ocorrencia"
              label="Histórico"
              variant="outlined"
              multiline
              rows={6}
              fullWidth
              required
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.historico_ocorrencia
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                  mt: 2,
                }}
              >
                <IconSalvar sx={{ mx: 1 }} />
                ATUALIZAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </form>
  );
};
export default EditarOcorrencia;
