import { Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ dataRegistros }) => {
  const dados_ocorrencias = {
    series: [dataRegistros.Ano_passado, dataRegistros.Ano_atual],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },

      xaxis: {
        categories: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
      },
    },
  };
  return (
    <Box p={1}>
      <ReactApexChart
        options={dados_ocorrencias.options}
        series={dados_ocorrencias.series}
        type="line"
        height={350}
      />
    </Box>
  );
};
export default LineChart;
