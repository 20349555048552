import Box from "@mui/material/Box";
import ListSelect from "../../ComponentsFormulario/ListSelect";
import { Grid, Button, Typography, Card, Link } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usePagination from "../../../../Pagination";
import Pagination from "@mui/material/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Aeronaves from "../../../Relatorio/Aeronaves";
import { URL_API } from "../../../../constants";
import CachedIcon from "@mui/icons-material/Cached";

const PesquisarAeronave = () => {
  const [valuesInput, setValuesInput] = useState([]);
  const [aeronaves, setAeronaves] = useState([]);
  const reset = () => {
    setValuesInput([]);
  };
  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisaraeronave`, {
        token: localStorage.token,
        dados_aeronaves: valuesInput,
      })
      .then(function (response) {
        if (response.data.status === "warning") {
          toast(response.data.message, {
            type: response.data.status,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setAeronaves([]);
        } else {
          setAeronaves(response.data);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [valuesInput]);

  const PER_PAGE = 8;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(Math.ceil(aeronaves.length / PER_PAGE));

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    setCount(Math.ceil(aeronaves.length / PER_PAGE));
  }, [aeronaves]);
  const _DATA = usePagination(aeronaves, PER_PAGE);
  return (
    <form>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography sx={{ color: "#072650", fontWeight: "700" }} variant="h5">
          Pesquisar Aeronave
          <Box sx={{ textAlign: "right", mt: -4 }}>
            <Button
              type="submit"
              variant="contained"
              onClick={reset}
              sx={{
                backgroundColor: "#0D6EFD",
                borderRadius: "10px",
              }}
            >
              <CachedIcon />
            </Button>
          </Box>
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Tipo Uso"
              list={["Aeromédico/Bombeiro", "Policial", "VIP"]}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.tipo_uso : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  tipo_uso: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Situação"
              list={["Ativa", "Inativa"]}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.status_aeronave
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  status_aeronave: valor,
                }))
              }
            />
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: "end",
            }}
          >
            {aeronaves.length > 0 ? (
              <PDFDownloadLink
                document={<Aeronaves dados={aeronaves} />}
                fileName="Aeronaves.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="outlined" sx={{ mx: 2 }}>
                      <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                    </Button>
                  )
                }
              </PDFDownloadLink>
            ) : (
              ""
            )}
          </Grid>
          <Grid container>
            {_DATA.currentData().map((item) => {
              return (
                <Grid item xs={12} sm={12} md={3} sx={{ px: 2, my: 2 }}>
                  <Card
                    fullWidth
                    sx={{
                      p: 1,
                      minHeight: "260px",
                      border: "1px solid #d3d3d3",
                      minWidth: "250px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Tipo Uso:</h4>{" "}
                      {item.tipo_uso_aeronave}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Prefixo:</h4>{" "}
                      {item.prefixo_aeronave}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Apelido:</h4>{" "}
                      {item.apelido_aeronave}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, p: 1 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      <h4 style={{ display: "inline" }}>Status:</h4>{" "}
                      {item.status_aeronave}
                    </Typography>
                    <Box fullWidth sx={{ textAlign: "right", pr: 1 }}>
                      <Link
                        variant="contained"
                        sx={{ mt: 1 }}
                        href={"visualizaraeronave/" + item.id}
                      >
                        <Button variant="contained" sx={{ m: 1 }}>
                          <VisibilityOutlinedIcon />
                        </Button>
                      </Link>
                      <Link
                        variant="contained"
                        sx={{ mt: 1 }}
                        href={"editaraeronave/" + item.id}
                      >
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{ m: 1 }}
                        >
                          <EditIcon />
                        </Button>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              px: 2,
              my: 2,
              justifySelf: "center",
            }}
          >
            {count > 0 ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={handleChangePage}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </form>
  );
};
export default PesquisarAeronave;
