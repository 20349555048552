import Box from "@mui/material/Box";
import InputMask from "react-input-mask";
import ListSelect from "../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography, Link, Card } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../../Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import OcorrenciaPesquisa from "../../Relatorio/OcorrenciaPesquisa";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import EditIcon from "@mui/icons-material/Edit";
import {
  URL_API,
  tipo_natureza_ocorrencia,
  tipo_ocorrencia_natureza,
} from "../../../constants";
import {
  BuscarAeronaves,
  BuscarCidades,
  FormatarDataBanco,
  FormatarHoras,
} from "../../../functions";
const PesquisarOcorrencia = () => {
  const [valuesInput, setValuesInput] = useState([]);
  const [cidades_ac, setCidades_ac] = useState([]);
  const [aeronaves, setAeronaves] = useState([]);
  const [horaspesquisa, setHoraspesquisa] = useState();
  const [countpesquisa, setCountpesquisa] = useState();

  const [ocorrencias, setOcorrencias] = useState([]);

  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      setAeronaves(await BuscarAeronaves(localStorage.token));
      setCidades_ac(await BuscarCidades());
    }

    fetchData();
  }, []);
  //Buscar Fornecedores

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/pesquisarocorrencia`, {
        token: localStorage.token,
        dados_ocorrencias: valuesInput,
      })
      .then((response) => {
        if (response.data.status === "warning") {
          toast(response.data.message, {
            type: response.data.status,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOcorrencias([]);
        } else {
          setOcorrencias(response.data.ocorrencia.rows);
          setCountpesquisa(response.data.ocorrencia.count);
          setHoraspesquisa(response.data.horas_ocorrencias);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const PER_PAGE = 8;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(Math.ceil(ocorrencias.length / PER_PAGE));

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    setCount(Math.ceil(ocorrencias.length / PER_PAGE));
  }, [ocorrencias]);

  const _DATA = usePagination(ocorrencias, PER_PAGE);
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Grid container>
          <Grid xs={12} md={12}>
            <Typography
              sx={{ m: 2, color: "#072650", fontWeight: "700" }}
              variant="h5"
            >
              Ocorrências
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask mask="99/99/9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="data_inicial"
                  name="data_inicial"
                  label="Data Inicial"
                  variant="standard"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask mask="99/99/9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  fullWidth
                  id="data_final"
                  name="data_final"
                  label="Data Final"
                  variant="standard"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <ListSelect
              title="Aeronave"
              list={aeronaves}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.aeronave : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  aeronave: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <ListSelect
              title="Cidade"
              list={cidades_ac}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.cidade_ocorrencia
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  cidade_ocorrencia: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ px: 2 }}>
            <ListSelect
              title="Tipo"
              list={Object.keys(tipo_ocorrencia_natureza)}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.tipo_ocorrencia
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  tipo_ocorrencia: valor,
                }))
              }
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ backgroundColor: "#0D6EFD", borderRadius: "10px", m: 1 }}
              >
                <SearchIcon sx={{ mx: 1 }} />
                Buscar
              </Button>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "end",
              }}
            >
              {ocorrencias.length > 0 ? (
                <PDFDownloadLink
                  document={
                    <OcorrenciaPesquisa
                      dados={ocorrencias}
                      horas={horaspesquisa}
                      count={countpesquisa}
                    />
                  }
                  fileName="Ocorrências.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <CircularProgress />
                    ) : (
                      <Button variant="outlined" sx={{ mx: 2 }}>
                        <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid>
            <Grid container>
              {_DATA.currentData().map((item) => {
                return (
                  <Grid
                    xs={12}
                    sm={12}
                    md={_DATA.currentData().length > 1 ? 3 : 12}
                    sx={{ px: 2, my: 2, minWidth: "300px" }}
                  >
                    <Card fullWidth sx={{ p: 1, pt: 2 }}>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>Aeronave: </h4>
                        {item.aeronave.apelido_aeronave}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>Comandante: </h4>
                        {item.comandante.nome_completo_servidor}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>Copiloto: </h4>
                        {item.copiloto.nome_completo_servidor}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>Data: </h4>
                        {FormatarDataBanco(item.data_ocorrencia)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>
                          Horímetro Inicial:
                        </h4>
                        {item.horimetro_inicial_ocorrencia}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>
                          Duração da Ocorrência:
                        </h4>
                        {FormatarHoras(item.duracao_ocorrencia)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        <h4 style={{ display: "inline" }}>
                          Tipo da Ocorrência:
                        </h4>
                        {item.tipo_ocorrencia}
                      </Typography>
                      <Box fullWidth sx={{ textAlign: "right", bottom: 0 }}>
                        <Link
                          variant="contained"
                          href={"visualizarocorrencia/" + item.id}
                        >
                          <Button variant="contained" sx={{}}>
                            <VisibilityOutlinedIcon />
                          </Button>
                        </Link>
                        <Link
                          variant="contained"
                          href={"editarocorrencia/" + item.id}
                        >
                          <Button
                            variant="contained"
                            color="warning"
                            sx={{ m: 1 }}
                          >
                            <EditIcon />
                          </Button>
                        </Link>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  px: 2,
                  my: 2,
                  justifySelf: "center",
                }}
              >
                {count > 0 ? (
                  <Pagination
                    count={count}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={handleChangePage}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </form>
  );
};
export default PesquisarOcorrencia;
