import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import ListSelect from "../../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography, Link } from "@mui/material";
import IconSalvar from "@mui/icons-material/FileOpenOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Abastecimento from "../../../Relatorio/Abastecimento";
import { URL_API } from "../../../../constants";

const EditarAbastecimento = () => {
  const [aeronaves, setAeronaves] = useState([]);
  const [aeronave, setAeronave] = useState();
  const apelido = [];
  const [data_abastecimento, setData] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState();
  const nome_empresa = [];

  const [combustiveis, setCombustiveis] = useState([]);
  const [combustivel, setCombustivel] = useState([]);

  const [valuesInput, setValuesInput] = useState([]);

  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  const { id } = useParams();
  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarabastecimento`, {
        token: localStorage.token,
        id: id,
      })
      .then(function (response) {
        setValuesInput(response.data);
        setEmpresa(response.data.empresa.descricao_empresa);
        setCombustivel(response.data.combustivei.descricao_combustivel);
        setAeronave(response.data.aeronave.apelido_aeronave);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);
  //Buscar Aeronaves e Buscar Empresas
  if (aeronaves.length === 0 && empresas.length === 0) {
    axios
      .post(`${URL_API}/pesquisaraeronave`, {
        status: "Ativa",
      })
      .then(function (response) {
        if (response.data.message) {
          apelido.push("Nenhuma Aeronave Ativa");
        } else {
          response.data.map((item) => apelido.push(item.apelido_aeronave));
        }
        setAeronaves(apelido);
      })
      .catch(function (error) {});

    axios
      .post(`${URL_API}/pesquisarempresa`, {
        status: "Ativa",
      })
      .then(function (response) {
        if (response.data.message) {
          nome_empresa.push("Nenhuma Empresa Ativa");
        } else {
          response.data.map((item) =>
            nome_empresa.push(item.descricao_empresa)
          );
        }
        setEmpresas(nome_empresa);
      })
      .catch(function (error) {});
  }
  //Buscar Combustiveis
  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarempresa`, {
        token: localStorage.token,
        descricao: empresa,
      })
      .then(function (response) {
        setCombustiveis(response.data);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [empresa]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/editarabastecimento`, {
        token: localStorage.token,
        id: id,
        empresa: empresa,
        combustivel: combustivel,
        apelido: aeronave,
        litros_abastecimento: valuesInput.litros_abastecimento,
        data_abastecimento: valuesInput.data_abastecimento,
        local_abastecimento: valuesInput.local_abastecimento,
        recibo_abastecimento: valuesInput.recibo_abastecimento,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  if (
    valuesInput.data_abastecimento !== undefined &&
    data_abastecimento.length === 0
  ) {
    const data_array = valuesInput.data_abastecimento.split("-");
    const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
    setData(new_data);
    setValuesInput((prevState) => ({
      ...prevState,
      data_abastecimento: new_data,
    }));
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Editar Abastecimento
        </Typography>
        <Grid
          container
          sx={{
            textAlign: "right",
            justifyContent: "end",
          }}
        >
          <PDFDownloadLink
            document={<Abastecimento dados={valuesInput} />}
            fileName="Aeronave.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" sx={{ mx: 2 }}>
                  <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                </Button>
              )
            }
          </PDFDownloadLink>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Empresa"
              list={empresas}
              valor={empresa}
              handleChange={(valor) => setEmpresa(valor)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Combustível"
              list={combustiveis}
              valor={combustivel}
              handleChange={(valor) => setCombustivel(valor)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Aeronave"
              list={aeronaves}
              valor={aeronave}
              handleChange={(valor) => setAeronave(valor)}
              required={true}
            />
          </Grid>

          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="litros"
              name="litros_abastecimento"
              label="Litros"
              variant="standard"
              required
              defaultValue=" "
              value={valuesInput.litros_abastecimento}
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="99/99/9999"
              defaultValue=" "
              value={valuesInput.data_abastecimento}
              onChange={handleaddValues}
            >
              {() => (
                <TextField
                  id="data"
                  name="data_abastecimento"
                  label="Data"
                  variant="standard"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="local"
              name="local_abastecimento"
              label="Local"
              variant="standard"
              required
              defaultValue=" "
              value={valuesInput.local_abastecimento}
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="recibo"
              name="recibo_abastecimento"
              label="Recibo"
              variant="standard"
              required
              defaultValue=" "
              value={valuesInput.recibo_abastecimento}
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={2} sx={{ px: 2, my: 2 }}>
              <Link
                href="/abastacimentosaeronave"
                sx={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "gray",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <ReplyOutlinedIcon sx={{ mr: 1 }} />
                  Voltar
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={2} sx={{ px: 2, my: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                <IconSalvar sx={{ mr: 1 }} />
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </form>
  );
};

export default EditarAbastecimento;
