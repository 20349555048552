import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Menu from "../components/Menu";
import PageLogin from "../Pages/PageLogin";
//SERVIDOR
import NovoServidor from "../components/Formulario/Servidor/NovoServidor";
import PesquisarServidor from "../components/Formulario/Servidor/PesquisarServidor";
import EditarServidor from "../components/Formulario/Servidor/EditarServidor";
import VisualizarServidor from "../components/Visualizar/VisualizarServidor";
//AERONAVE
import NovaAeronave from "../components/Formulario/Aeronave/Aeronave/NovaAeronave";
import PesquisarAeronave from "../components/Formulario/Aeronave/Aeronave/PesquisarAeronave";
import EditarAeronave from "../components/Formulario/Aeronave/Aeronave/EditarAeronave";
import VisualizarAeronave from "../components/Visualizar/VisualizarAeronave";
//ABASTECIMENTO
import NovoAbastecimento from "../components/Formulario/Aeronave/Abastecimento/NovoAbastecimento";
import PesquisarAbastecimento from "../components/Formulario/Aeronave/Abastecimento/PesquisarAbastecimento";
import EditarAbastecimento from "../components/Formulario/Aeronave/Abastecimento/EditarAbastecimento";
//Ocorrência
import NovaOcorrencia from "../components/Formulario/Ocorrencia/RegistrarOcorrencia";
import PesquisarOcorrencia from "../components/Formulario/Ocorrencia/PesquisarOcorrencia";
import NotFound from "../Pages/PageNotFound";
import VisualizarOcorrencia from "../components/Visualizar/VisualizarOcorrencia";
import EditarOcorrencia from "../components/Formulario/Ocorrencia/EditarOcorrencia";
//Dashboard
import DashboardPage from "../Pages/PageDashboard";
const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/" replace={true} />;
  }
  return children;
};

function RoutesApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/" element={<PageLogin token={localStorage.token} />} />

        <Route path="/" element={<Menu />}>
          <Route
            path="adicionarservidor"
            element={
              <ProtectedRoute token={localStorage.token}>
                <NovoServidor />
              </ProtectedRoute>
            }
          />
          <Route
            path="visualizarservidor/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <VisualizarServidor />
              </ProtectedRoute>
            }
          />
          <Route
            path="editarservidor/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <EditarServidor />
              </ProtectedRoute>
            }
          />
          <Route
            path="visualizaraeronave/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <VisualizarAeronave />
              </ProtectedRoute>
            }
          />
          <Route
            path="pesquisarservidor"
            element={
              <ProtectedRoute token={localStorage.token}>
                <PesquisarServidor />
              </ProtectedRoute>
            }
          />

          <Route
            path="adicionaraeronave"
            element={
              <ProtectedRoute token={localStorage.token}>
                <NovaAeronave />
              </ProtectedRoute>
            }
          />
          <Route
            path="adicionaraeronave"
            element={
              <ProtectedRoute token={localStorage.token}>
                <NovaAeronave />
              </ProtectedRoute>
            }
          />
          <Route
            path="pesquisaraeronave"
            element={
              <ProtectedRoute token={localStorage.token}>
                <PesquisarAeronave />
              </ProtectedRoute>
            }
          />
          <Route
            path="editaraeronave/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <EditarAeronave />
              </ProtectedRoute>
            }
          />
          <Route
            path="abastacimentosaeronave"
            element={
              <ProtectedRoute token={localStorage.token}>
                <PesquisarAbastecimento />
              </ProtectedRoute>
            }
          />
          <Route
            path="registrarabastecimento"
            element={
              <ProtectedRoute token={localStorage.token}>
                <NovoAbastecimento />
              </ProtectedRoute>
            }
          />
          <Route
            path="editarabastecimento/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <EditarAbastecimento />
              </ProtectedRoute>
            }
          />

          <Route
            path="registrarocorrencia"
            element={
              <ProtectedRoute token={localStorage.token}>
                <NovaOcorrencia />
              </ProtectedRoute>
            }
          />
          <Route
            path="pesquisarocorrencia"
            element={
              <ProtectedRoute token={localStorage.token}>
                <PesquisarOcorrencia />
              </ProtectedRoute>
            }
          />

          <Route
            path="editarocorrencia/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <EditarOcorrencia />
              </ProtectedRoute>
            }
          />
          <Route
            path="visualizarocorrencia/:id"
            element={
              <ProtectedRoute token={localStorage.token}>
                <VisualizarOcorrencia />
              </ProtectedRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute token={localStorage.token}>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesApp;
