import { Grid, Button, Typography, Link, Box } from "@mui/material";
const DadosPoliciais = (props) => {
  return (
    <>
      {props.dados.map((item) => (
        <Grid container>
          <Grid item xs={12} md={12} sx={{ px: 2 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Itens da Ocorrência
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: 3 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Armas:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.armas_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Carros:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.carros_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Dinheiro:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.dinheiro_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Drogas:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.drogas_ocorrencia}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx={{ px: 3 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Motos:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {" "}
              {item.motos_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Munição:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.municao_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#f5f5f5",
                p: 1,
              }}
            >
              Presos:
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.presos_ocorrencia}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
export default DadosPoliciais;
