import { Container, Grid } from "@mui/material";
import LogoHarpia from "../../components/LogoHarpia";
import FormularioLogin from "../../components/Formulario/Login";
import FooterLogin from "../../components/Footer/FooterLogin";
import { URL_API } from "../../constants";
const TelaLogin = (props) => {
  if (props.token !== undefined) {
    localStorage.removeItem("token");
    localStorage.removeItem("nome");
    localStorage.removeItem("id");
  }
  return (
    <Container>
      <style>
        {
          "body {background-image: linear-gradient(#072650 50%, #104283);  height: auto; background-color: #104283; background-repeat: no-repeat;}"
        }
      </style>
      <Grid container justifyContent="center" sx={{ justifySelf: "center" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: "600px",
            background: "white",
            borderRadius: "10px",
            marginTop: "80px",
            boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
          }}
        >
          <LogoHarpia />
          <FormularioLogin />
          <FooterLogin />
        </Grid>
      </Grid>
    </Container>
  );
};
export default TelaLogin;
