import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import brasao_ciopaer from "./brasao_ciopaer.png";

// Create styles
const styles = StyleSheet.create({
  view: {
    flexDirection: "row",
  },
  header: {
    textAlign: "center",
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
  },
  section: {
    flexGrow: 1,
    margin: 2,
  },
  header_image: {
    width: "60px",
    margin: "10px",
  },
  font: {
    fontWeigh: "500",
  },
  img: {
    width: "80px",
    padding: "15px",
    textAlign: "center",
  },
  text: {
    fontWeigh: "300",
    margin: "15px 20px ",
  },

  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "gray",
    borderWidth: 2,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 15,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "16.6%",
    padding: "25px 0px",
    borderStyle: "solid",
    borderColor: "gray",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    textAlign: "center",
    fontSize: 10,
  },
  page: {
    paddingBottom: "80px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
const data = new Date();

const new_data = (data) => {
  const data_array = data.split("-");
  const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
  return new_data;
};
const new_horas = (hora) => {
  const hora_array = hora.split(":");
  const new_hora = hora_array[0] + "H:" + hora_array[1] + "min";
  return new_hora;
};
const brasao =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Bras%C3%A3o_do_Acre.svg/1200px-Bras%C3%A3o_do_Acre.svg.png";
// Create Document Component

const Ocorrencias = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View
        style={[
          styles.view,
          { borderBottom: "1px solid gray", marginHorizontal: "10px" },
        ]}
        fixed
      >
        <View style={styles.section}>
          <Image style={styles.header_image} src={brasao} />
        </View>
        <View
          style={[
            styles.section,
            {
              marginTop: "20px",
              marginRight: "180px",
            },
          ]}
        >
          <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
            Estado do Acre
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Secretaria de Estado de Segurança Pública
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Centro Integrado de Operações Aéreas
          </Text>
        </View>
        <View style={[styles.section, { alignItems: "rigth" }]}>
          <Image
            style={[styles.header_image, { alignItems: "rigth" }]}
            src={brasao_ciopaer}
          />
        </View>
      </View>

      <View style={{ textAlign: "center", marginVertical: "15px" }} fixed>
        <Text style={{ fontSize: "16px" }}>Relatório de Ocorrências</Text>
        <Text
          style={{ fontSize: "14px" }}
          render={() => `${data.getFullYear()}`}
          fixed
        ></Text>
      </View>
      <View>
        <View style={styles.table}>
          <View style={styles.tableRow} fixed>
            <View style={[styles.tableCol, { backgroundColor: "#c8c8c8" }]}>
              <Text style={styles.tableCell}>Aeronave</Text>
            </View>
            <View style={[styles.tableCol, { backgroundColor: "#c8c8c8" }]}>
              <Text style={styles.tableCell}>Data</Text>
            </View>
            <View style={[styles.tableCol, { backgroundColor: "#c8c8c8" }]}>
              <Text style={styles.tableCell}>Duração</Text>
            </View>
            <View style={[styles.tableCol, { backgroundColor: "#c8c8c8" }]}>
              <Text style={styles.tableCell}>Tipo Ocorrência</Text>
            </View>
            <View style={[styles.tableCol, { backgroundColor: "#c8c8c8" }]}>
              <Text style={styles.tableCell}>Comandante</Text>
            </View>
            <View style={[styles.tableCol, { backgroundColor: "#c8c8c8" }]}>
              <Text style={styles.tableCell}>Copiloto</Text>
            </View>
          </View>
          {props.dados.map((item, key) => (
            <>
              {key == 0 ? "" : key % 10 == 0 ? <Text break> </Text> : ""}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text
                    style={styles.tableCell}
                    render={() => `${item.aeronave.apelido_aeronave}`}
                    fixed
                  />
                </View>
                <View style={styles.tableCol}>
                  <Text
                    style={styles.tableCell}
                    render={() => `${new_data(item.data_ocorrencia)}`}
                  ></Text>
                </View>

                <View style={styles.tableCol}>
                  <Text
                    style={styles.tableCell}
                    render={() => `${new_horas(item.duracao_ocorrencia)}`}
                  ></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text
                    style={styles.tableCell}
                    render={() => `${item.tipo_ocorrencia}`}
                  ></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text
                    style={styles.tableCell}
                    render={() => `${item.comandante.nome_completo_servidor}`}
                  ></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text
                    style={styles.tableCell}
                    render={() => `${item.copiloto.nome_completo_servidor}`}
                  ></Text>
                </View>
              </View>
            </>
          ))}
        </View>
      </View>
      <View style={{ padding: "10px", marginVertical: "10px" }}>
        <Text
          style={{
            fontSize: "12px",
            padding: "5px",
            fontWeight: "bold",
          }}
        >
          Horas de Vôo : {props.horas}
        </Text>

        <Text
          style={{
            fontSize: "12px",
            padding: "5px",
            fontWeight: "bold",
            display: "inline",
          }}
        >
          Ocorrências : {props.count}
        </Text>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default Ocorrencias;
