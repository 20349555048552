import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import brasao_ciopaer from "./brasao_ciopaer.png";
// Create styles

const styles = StyleSheet.create({
  view: {
    flexDirection: "row",
  },
  header: {
    textAlign: "center",
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
  },
  section: {
    flexGrow: 1,
    margin: 2,
  },
  header_image: {
    width: "60px",
    margin: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
const data = new Date();

const new_data = (data) => {
  const data_array = data.split("-");
  const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
  return new_data;
};
const new_horas = (hora) => {
  const hora_array = hora.split(":");
  const new_hora = hora_array[0] + "h:" + hora_array[1] + "min";
  return new_hora;
};
const brasao =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Bras%C3%A3o_do_Acre.svg/1200px-Bras%C3%A3o_do_Acre.svg.png";

// Create Document Component
const OcorrenciaUnica = (props) => (
  <Document>
    <Page size="A4">
      <View
        style={[
          styles.view,
          { borderBottom: "1px solid gray", marginHorizontal: "10px" },
        ]}
      >
        <View style={styles.section}>
          <Image style={styles.header_image} src={brasao} />
        </View>
        <View
          style={[
            styles.section,
            {
              marginTop: "20px",
              marginRight: "180px",
            },
          ]}
        >
          <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
            Estado do Acre
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Secretaria de Estado de Segurança Pública
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Centro Integrado de Operações Aéreas
          </Text>
        </View>
        <View style={[styles.section, { alignItems: "rigth" }]}>
          <Image
            style={[styles.header_image, { alignItems: "rigth" }]}
            src={brasao_ciopaer}
          />
        </View>
      </View>

      <View style={{ textAlign: "center", marginVertical: "15px" }}>
        <Text style={{ fontSize: "16px" }}>Relatório de Ocorrência</Text>
        <Text
          style={{ fontSize: "14px" }}
          render={() => `${data.getFullYear()}`}
          fixed
        ></Text>
      </View>
      {props.dados.map((item) => (
        <>
          <View style={[styles.view, { marginHorizontal: "10px" }]}>
            <View style={styles.section}>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                }}
              >
                Aeronave
              </Text>
              <Text
                render={() => `${item.aeronave.apelido_aeronave}`}
                style={{
                  fontSize: "13px",
                  padding: "5px",
                  color: "#424242",
                }}
              ></Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                }}
              >
                Base
              </Text>
              <Text
                render={() => `${item.base_ciopaer}`}
                style={{ fontSize: "13px", padding: "5px" }}
              ></Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                }}
              >
                Comandante
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.comandante.nome_completo_servidor}
              </Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Copiloto
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.copiloto.nome_completo_servidor}
              </Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Cidade da Ocorrência
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.cidade_ocorrencia}
              </Text>
            </View>
            <View style={styles.section}>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                }}
              >
                Data da Ocorrência
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {new_data(item.data_ocorrencia)}
              </Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Hora do Inicio da Ocorrência
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.horimetro_inicial_ocorrencia}
              </Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Hora Final da Ocorrência
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.horimetro_final_ocorrencia}
              </Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Duração da Ocorrência - Horímetro
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.duracao_ocorrencia_horimetro}
              </Text>
              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Duração da Ocorrência - Horas e Minutos
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {new_horas(item.duracao_ocorrencia)}
              </Text>

              <Text
                style={{
                  backgroundColor: "#f3f3f3",
                  fontSize: "13px",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Tipo e Natureza da Ocorrência
              </Text>
              <Text
                style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
              >
                {item.tipo_ocorrencia} - {item.natureza_ocorrencia}
              </Text>
            </View>
          </View>
          {Object.is(item.tipo_ocorrencia, "Resgate") ? (
            item.hasOwnProperty("dados_ocorrencia") ? (
              item.dados_da_ocorrencia.map((item) => (
                <View style={{ margin: 2, marginHorizontal: "10px" }}>
                  <Text
                    style={{
                      backgroundColor: "#f3f3f3",
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Dados da Vitima
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Nome: {item.nome_vitima}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Idade:{item.idade_vitima}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    CPF:
                    {item.cpf_vitima}
                  </Text>
                </View>
              ))
            ) : (
              <View style={{ margin: 2, marginHorizontal: "10px" }}>
                <Text
                  style={{
                    backgroundColor: "#f3f3f3",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Dados da Vitima
                </Text>
                <Text style={{ fontSize: "13px", padding: "5px" }}>
                  Não se Aplica
                </Text>
              </View>
            )
          ) : (
            <Text style={{ fontSize: "13px", padding: "5px" }}></Text>
          )}
          {Object.is(item.tipo_ocorrencia, "Policial") ? (
            item.hasOwnProperty("dados_ocorrencia") ? (
              item.dados_da_ocorrencia.map((item) => (
                <View style={{ margin: 2, marginHorizontal: "10px" }}>
                  <Text
                    style={{
                      backgroundColor: "#f3f3f3",
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Itens da Ocorrência
                  </Text>

                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Armas: {item.armas_ocorrencia}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Carros: {item.carros_ocorrencia}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Dinheiro:
                    {item.dinheiro_ocorrencia}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Drogas:
                    {item.drogas_ocorrencia}
                  </Text>

                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Motos:
                    {item.motos_ocorrencia}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Munição:
                    {item.municao_ocorrencia}
                  </Text>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    Presos:
                    {item.presos_ocorrencia}
                  </Text>
                </View>
              ))
            ) : (
              <View style={{ margin: 2, marginHorizontal: "10px" }}>
                <Text
                  style={{
                    backgroundColor: "#f3f3f3",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Itens da Ocorrência
                </Text>
                <Text style={{ fontSize: "13px", padding: "5px" }}>
                  Não se Aplica
                </Text>
              </View>
            )
          ) : (
            <Text style={{ fontSize: "13px", padding: "5px" }}></Text>
          )}

          <View style={{ margin: 2, marginHorizontal: "10px" }}>
            <Text
              style={{
                backgroundColor: "#f3f3f3",
                fontSize: "13px",
                padding: "5px",
              }}
            >
              Operadores
            </Text>

            {item.hasOwnProperty("operadores_ocorrencia") ? (
              item.operadores_ocorrencia.map((dados) => (
                <View>
                  <Text
                    style={{
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    {dados.nome_completo_servidor} - {dados.funcao_servidor}
                  </Text>
                </View>
              ))
            ) : (
              <View style={{ marginHorizontal: "10px" }}>
                <Text
                  style={{ fontSize: "13px", padding: "5px", color: "#424242" }}
                >
                  Não se Aplica
                </Text>
              </View>
            )}
          </View>
          <View style={{ margin: 2, marginHorizontal: "10px" }}>
            <Text
              style={{
                backgroundColor: "#f3f3f3",
                fontSize: "13px",
                padding: "5px",
              }}
            >
              Histórico
            </Text>

            <Text
              style={{
                fontSize: "13px",
                padding: "5px",
                color: "#424242",
              }}
            >
              {item.historico_ocorrencia}
            </Text>
          </View>
        </>
      ))}

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default OcorrenciaUnica;
