import { Box, Container } from "@mui/system";

const LogoHarpia = () =>{
    return(
        <Box>
            <Box sx={{display:"flex", justifyContent:"center"}}>
                <img src="/imagens/harpia.png" width={250} />
            </Box>
            <Box sx={{color:"#5F5F5F", display:"flex", justifyContent:"center", textAlign:"center"}}>
                <h3>Centro Integrado de Operações Aéreas</h3>
            </Box>
        </Box>
        
    )
}
export default LogoHarpia;