import { Container } from "@mui/material";

import { Box } from "@mui/system";
const NotFound = () => {
  return (
    <Container>
      <Box sx={{ textAlign: "center" }}>
        <h2 style={{ fontSize: "50px" }}>404 Page not Found!</h2>
        <a
          href="/"
          style={{
            padding: 10,
            background: "#104283",
            color: "white",
            borderRadius: "5px",
            textDecoration: "none",
          }}
        >
          Voltar
        </a>
      </Box>
    </Container>
  );
};
export default NotFound;
