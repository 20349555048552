import { URL_API } from "./constants";
import axios from "axios";

export async function BuscarAeronaves(token) {
  try {
    const status_aeronave = { status_aeronave: "Ativa" };
    const response = await axios.post(`${URL_API}/pesquisaraeronave`, {
      token: token,
      dados_aeronaves: status_aeronave,
    });
    return response.data.map((item) => ({
      id: item.id,
      nome: item.apelido_aeronave,
    }));
  } catch (error) {
    return [];
  }
}
export async function BuscarPerfil(token) {
  try {
    const response = await axios.post(`${URL_API}/pesquisarperfil`, {
      token: token,
    });
    return response.data.map((item) => ({
      id: item.id,
      nome: item.descricao_perfil,
    }));
  } catch (error) {
    return [];
  }
}
export async function BuscarEmpresa(token) {
  try {
    const response = await axios.post(`${URL_API}/pesquisarempresa`, {
      token: token,
      status: "Ativa",
    });
    return response.data.map((item) => ({
      id: item.id,
      nome: item.descricao_empresa,
    }));
  } catch (error) {
    return [];
  }
}
export async function BuscarServidores(token, funcao) {
  const dados_servidores = { situacao: "Ativo", funcao: funcao };
  try {
    const response = await axios.post(`${URL_API}/pesquisarservidor`, {
      token: token,
      dados_servidores: dados_servidores,
    });
    return response.data.map((item) => ({
      id: item.id,
      nome: item.nome_completo_servidor,
    }));
  } catch (error) {
    return [];
  }
}
export async function BuscarCidades() {
  try {
    const response = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados/ac/municipios"
    );

    return response.data.map((item) => [item.nome]);
  } catch (error) {
    return [];
  }
}
export function FormatarHoras(hora) {
  if (hora.indexOf(":") > -1) {
    const hora_array = hora.split(":");
    const new_hora = hora_array[0] + " Horas e " + hora_array[1] + " Minutos";
    return new_hora;
  } else {
    const new_hora = "0 Horas e " + "0 Minutos";
    return new_hora;
  }
}
export function FormatarDataBanco(hora) {
  const data_array = hora.split("-");
  const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
  return new_data;
}
