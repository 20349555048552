import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import brasao_ciopaer from "./brasao_ciopaer.png";
// Create styles
const styles = StyleSheet.create({
  view: {
    flexDirection: "row",
  },
  header: {
    textAlign: "center",
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
  },
  section: {
    flexGrow: 1,
    margin: 2,
  },
  header_image: {
    width: "60px",
    margin: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
const data = new Date();

const new_data = (data) => {
  const data_array = data.split("-");
  const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
  return new_data;
};

const brasao =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Bras%C3%A3o_do_Acre.svg/1200px-Bras%C3%A3o_do_Acre.svg.png";

// Create Document Component
const Aeronave = (props) => (
  <Document>
    <Page size="A4">
      <View
        style={[
          styles.view,
          { borderBottom: "1px solid gray", marginHorizontal: "10px" },
        ]}
      >
        <View style={styles.section}>
          <Image style={styles.header_image} src={brasao} />
        </View>
        <View
          style={[
            styles.section,
            {
              marginTop: "20px",
              marginRight: "180px",
            },
          ]}
        >
          <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
            Estado do Acre
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Secretaria de Estado de Segurança Pública
          </Text>
          <Text style={{ fontSize: "14px" }}>
            Centro Integrado de Operações Aéreas
          </Text>
        </View>
        <View style={[styles.section, { alignItems: "rigth" }]}>
          <Image
            style={[styles.header_image, { alignItems: "rigth" }]}
            src={brasao_ciopaer}
          />
        </View>
      </View>
      <View style={{ textAlign: "center", marginVertical: "15px" }}>
        <Text style={{ fontSize: "16px" }}>Aeronave</Text>
      </View>
      <Text
        style={{
          fontSize: "13px",
          padding: "5px",
          margin: "10px",
          borderBottom: "1px solid gray",
        }}
      >
        Dados
      </Text>
      <View style={[styles.view, { marginHorizontal: "10px" }]}>
        <View style={styles.section}>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
            }}
          >
            Apelido
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.apelido_aeronave}
          </Text>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
            }}
          >
            Prefixo
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.prefixo_aeronave}
          </Text>

          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Tipo Uso
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.tipo_uso_aeronave}
          </Text>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
            }}
          >
            Fabricante
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.fabricante_aeronave}
          </Text>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Modelo
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.modelo_aeronave}
          </Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            N° Serie
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.numero_serie_aeronave}
          </Text>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Ano Fabricação 
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.ano_fabricacao_aeronave}
          </Text>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Situação
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.status_aeronave}
          </Text>
          <Text
            style={{
              backgroundColor: "#f3f3f3",
              fontSize: "13px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            Horas de Vôo
          </Text>
          <Text style={{ fontSize: "13px", padding: "5px" }}>
            {props.dados.horas_voo_aeronave}
          </Text>
        </View>
      </View>{" "}
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default Aeronave;
