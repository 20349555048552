import { Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const BarChart = ({
  nome_grafico,
  dataOcorrencias,
  dataRegistros,
  widChart,
}) => {
  const dados_ocorrencias = {
    series: [
      {
        name: nome_grafico,
        data: dataRegistros,
      },
    ],
    options: {
      title: {
        text: nome_grafico,
        style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#263238'
          },
      },
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          distributed: true,
          barHeight:`${dataRegistros.length>4?"90%":"40%"}`,
          horizontal: true,
          borderRadius: 4,
        },
      },
      colors: [
        "#3366CC",
        "#DC3912",
        "#FF9900",
        "#109618",
        "#990099",
        "#0099C6",
        "#DD4477",
        "#66AA00",
        "#B82E2E",
        "#316395",
      ],
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },

      stroke: {
        width: 1,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: dataOcorrencias,
      },
    },
  };
  if (dataOcorrencias.length > 0) {
    return (
      <Box p={3}>
        <ReactApexChart
          options={dados_ocorrencias.options}
          series={dados_ocorrencias.series}
          type="bar"
          height={widChart}
        />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          height: "430px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          p: 2,
        }}
      >
        Sem Registros
      </Box>
    );
  }
};
export default BarChart;
