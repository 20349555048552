import Box from "@mui/material/Box";
import { Grid, TextField, Button, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { useState, useEffect } from "react";
import ListSelect from "../../ComponentsFormulario/ListSelect";
const Resgate = ({ dados_ocorrencia, onFormChange }) => {
  const [inputValue, setInputValue] = useState(
    dados_ocorrencia != undefined ? dados_ocorrencia[0] : []
  );

  const handleChange = (event) => {
    setInputValue((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    onFormChange(inputValue);
  }, [inputValue, onFormChange]);
  return (
    <Box sx={{}}>
      <Typography sx={{ mx: 2, color: "gray", fontWeight: "600" }} variant="p">
        Dados da Vítima
      </Typography>
      <Typography
        sx={{
          mx: 2,
          color: "gray",
          fontWeight: "100",
          fontSize: "12px",
          display: "block",
        }}
        variant="p"
      >
        Em caso de transporte vítima
      </Typography>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} md={4} sx={{ px: 2, my: 2 }}>
          <TextField
            id="nome_vitima"
            name="nome_vitima"
            label="Nome Vítima"
            variant="standard"
            fullWidth
            value={inputValue != undefined ? inputValue.nome_vitima : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
          <InputMask
            mask="999.999.999-99"
            value={inputValue != undefined ? inputValue.cpf_vitima : ""}
            onChange={handleChange}
          >
            {() => (
              <TextField
                id="cpf_vitima"
                name="cpf_vitima"
                label="CPF"
                variant="standard"
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} md={2} sx={{ px: 2, my: 2 }}>
          <TextField
            id="idade_vitima"
            name="idade_vitima"
            label="Idade"
            variant="standard"
            fullWidth
            value={inputValue != undefined ? inputValue.idade_vitima : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ px: 2 }}>
          <ListSelect
            title="Sexo"
            list={["F", "M"]}
            valor={inputValue != undefined ? inputValue.sexo_vitima : ""}
            handleChange={(valor) =>
              setInputValue((prevValues) => ({
                ...prevValues,
                sexo_vitima: valor,
              }))
            }
            required={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Resgate;
