import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { Grid, TextField, Button, Typography, Link } from "@mui/material";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Servidor from "../Relatorio/Servidor";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { URL_API } from "../../constants";
import { FormatarDataBanco } from "../../functions";
let perfil = "";
let data = "";
let data_array = "";
const VisualizarServidor = () => {
  const [dadosServidor, setDadosServidor] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarservidor`, {
        id: id,
      })
      .then(function (response) {
        setDadosServidor(response.data);
        setDadosServidor((prevValues) => ({
          ...prevValues,
          perfil: response.data.perfi.descricao_perfil,
        }));
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);
  return (
    <Box
      sx={{
        background: "white",
        p: 2,
        m: 3,
        borderRadius: "10px",
        boxShadow: "10px 8px 25px rgba(5, 5, 5, 0.5)",
      }}
    >
      <Typography
        sx={{ m: 2, color: "#072650", fontWeight: "700" }}
        variant="h5"
      >
        Servidor
      </Typography>
      <Box textAlign={"right"}>
        <PDFDownloadLink
          document={<Servidor dados={dadosServidor} />}
          fileName="Servidor.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <CircularProgress />
            ) : (
              <Button variant="outlined" sx={{ mx: 2 }}>
                <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
              </Button>
            )
          }
        </PDFDownloadLink>
      </Box>

      <Grid container>
        <Grid item xs={12} md={6} sx={{ px: 2 }}>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Nome Completo
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.nome_completo_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Nome de Guerra
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.nome_guerra_servidor != null
              ? dadosServidor.nome_guerra_servidor
              : "Não se Aplica"}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            CPF
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.cpf_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            RG
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.rg_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Data de Nascimento
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {Object.keys(dadosServidor).length > 0
              ? FormatarDataBanco(dadosServidor.data_nascimento_servidor)
              : ""}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Matrícula
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.matricula_servidor != null
              ? dadosServidor.matricula_servidor
              : "Não se Aplica"}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Horas de Vôo
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.horas_voo_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Código ANAC
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.codigo_anac_servidor != null
              ? dadosServidor.codigo_anac_servidor
              : "Não se Aplica"}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Email
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.email_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Telefone
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.telefone_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Endereço
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.endereco_servidor}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ px: 2 }}>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Função
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.funcao_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Perfil
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.perfil}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Origem
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.origem_servidor}
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#e0e0e0",
              borderRadius: "5px",
              p: 1,
            }}
            variant="p"
          >
            Situação
          </Typography>
          <Typography
            sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
            variant="p"
          >
            {dadosServidor.status_servidor}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} sx={{ p: 2, m: 2 }}>
          <Link href="/pesquisarservidor" sx={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "gray",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <ReplyOutlinedIcon sx={{ mr: 1 }} />
              Voltar
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VisualizarServidor;
