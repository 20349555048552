import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import ListSelect from "../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography, Link } from "@mui/material";
import IconSalvar from "@mui/icons-material/UploadFile";
import axios from "axios";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  URL_API,
  funcao_servidor,
  origem_servidor,
  perfil_servidor,
} from "../../../constants";
import { FormatarDataBanco } from "../../../functions";
const EditarServidor = () => {
  const [valuesInput, setValuesInput] = useState([]);

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${URL_API}/editarservidor`, {
        token: localStorage.token,
        dados_servidor: valuesInput,
        id_servidor: id,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarservidor`, {
        id: id,
        token: localStorage.token,
      })
      .then(function (response) {
        setValuesInput(response.data);
        setValuesInput((prevValues) => ({
          ...prevValues,
          perfil: response.data.perfi.descricao_perfil,
        }));
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);
  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Editar Servidor
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Origem"
              list={origem_servidor}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.origem_servidor
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  origem_servidor: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Função"
              list={funcao_servidor}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.funcao_servidor
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  funcao_servidor: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              disabled="true"
              title="Perfil*"
              list={perfil_servidor}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.perfil : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  perfil: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Situação *"
              list={["Ativo", "Inativo"]}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.status_servidor
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  status_servidor: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="matricula_servidor"
              name="matricula_servidor"
              label="Matrícula"
              variant="outlined"
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.matricula_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="nome_guerra_servidor"
              name="nome_guerra_servidor"
              label="Nome de Guerra"
              variant="outlined"
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.nome_guerra_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="codigo_anac_servidor"
              name="codigo_anac_servidor"
              label="Código ANAC"
              variant="outlined"
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.codigo_anac_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              disabled
              id="horas_iniciais_servidor"
              name="horas_iniciais_servidor"
              label="Horas Iniciais"
              variant="outlined"
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.horas_iniciais_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="email_servidor"
              name="email_servidor"
              label="Email"
              variant="outlined"
              required
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.email_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="nome_completo_servidor"
              name="nome_completo_servidor"
              type="text"
              label="Nome Completo"
              variant="outlined"
              required
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.nome_completo_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="99/99/9999"
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? FormatarDataBanco(valuesInput.data_nascimento_servidor)
                  : ""
              }
              onChange={handleaddValues}
            >
              {() => (
                <TextField
                  id="data_nascimento_servidor"
                  name="data_nascimento_servidor"
                  label="Data de Nascimento"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="999.999.999-99"
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.cpf_servidor
                  : ""
              }
              onChange={handleaddValues}
            >
              {() => (
                <TextField
                  id="cpf_servidor"
                  name="cpf_servidor"
                  label="CPF"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="rg_servidor"
              name="rg_servidor"
              label="Nº RG civil"
              variant="outlined"
              required
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.rg_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask
              mask="(99)9 9999-9999"
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.telefone_servidor
                  : ""
              }
              onChange={handleaddValues}
            >
              {() => (
                <TextField
                  id="telefone_servidor"
                  name="telefone_servidor"
                  label="Telefone celular"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} md={6} sx={{ px: 2, my: 2 }}>
            <TextField
              id="endereco_servidor"
              name="endereco_servidor"
              label="Endereço"
              variant="outlined"
              required
              fullWidth
              defaultValue=" "
              value={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.endereco_servidor
                  : ""
              }
              onChange={handleaddValues}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={2} sx={{ px: 2, my: 2 }}>
              <Link href="/pesquisarservidor" sx={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "gray",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <ReplyOutlinedIcon sx={{ mr: 1 }} />
                  Voltar
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                  mt: 2,
                }}
              >
                <IconSalvar sx={{ mx: 1 }} />
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </form>
  );
};
export default EditarServidor;
