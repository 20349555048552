import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import ListSelect from "../../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography, Link } from "@mui/material";
import IconSalvar from "@mui/icons-material/FileOpenOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { URL_API } from "../../../../constants";
import { BuscarAeronaves, BuscarEmpresa } from "../../../../functions";
const NovoAbastecimento = () => {
  const [aeronaves, setAeronaves] = useState([]);

  const [empresas, setEmpresas] = useState([]);

  const [combustiveis, setCombustiveis] = useState([]);

  const [valuesInput, setValuesInput] = useState([]);

  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      setAeronaves(await BuscarAeronaves(localStorage.token));
      setEmpresas(await BuscarEmpresa(localStorage.token));
    }

    fetchData();
  }, []);

  //Buscar Combustiveis
  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarempresa`, {
        token: localStorage.token,
        id_empresa: valuesInput.empresa,
      })
      .then(function (response) {
        setCombustiveis(
          response.data[0].combustiveis.map((item) => ({
            id: item.id,
            nome: item.descricao_combustivel,
          }))
        );
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [valuesInput.empresa]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/registrarabastecimento`, {
        token: localStorage.token,
        dados_abastecimento: valuesInput,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (response.data.status === "success") {
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Registrar Abastecimento
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Empresa"
              list={empresas}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.empresa : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  empresa: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Combustível"
              list={combustiveis}
              valor={
                Object.keys(valuesInput).length > 0
                  ? valuesInput.combustivel
                  : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  combustivel: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Aeronave"
              list={aeronaves}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.aeronave : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  aeronave: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="litros"
              name="litros_abastecimento"
              label="Litros"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <InputMask mask="99/99/9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="data"
                  name="data_abastecimento"
                  label="Data"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="local"
              name="local_abastecimento"
              label="Local"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="recibo"
              name="recibo_abastecimento"
              label="Recibo"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={2} sx={{ px: 2, my: 3 }}>
              <Link
                href="/abastacimentosaeronave"
                sx={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "gray",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <ReplyOutlinedIcon sx={{ mr: 1 }} />
                  Voltar
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={2} sx={{ px: 2, my: 3 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                <IconSalvar sx={{ mr: 1 }} />
                Registrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </form>
  );
};

export default NovoAbastecimento;
