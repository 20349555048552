import { useState } from "react";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Users from "@mui/icons-material/GroupsOutlined";
import Aeronave from "@mui/icons-material/FlightOutlined";
import Ocorrencia from "@mui/icons-material/NotificationImportantOutlined";
import "./sidebar.css";
import { useParams } from "react-router-dom";

function ListCollapse(props) {
  const { open, name, ...other } = props;
  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }

  return (
    <li>
      <ListItem sx={{ p: 0, color: "#DDDBDB" }} button {...other}>
        <h3 style={{ fontWeight: "500" }}>{name}</h3>

        {icon}
      </ListItem>
    </li>
  );
}
function Icon(props) {
  let icons = null;
  if (props.name == "Servidor") {
    icons = <Users />;
  } else if (props.name == "Aeronave") {
    icons = <Aeronave />;
  } else {
    icons = <Ocorrencia />;
  }
  return <>{icons}</>;
}
const MenuList = (props) => {
  const list = props.list;
  const namelist = props.namelist;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <ListItem disablePadding onClick={handleClick} sx={{ background: "" }}>
      <ListItemButton sx={{ p: 0, px: 4 }}>
        <ListItemIcon sx={{ color: "#DDDBDB" }}>
          <Icon nameclass="te" name={namelist} />
        </ListItemIcon>
        <List sx={{ p: 0, color: "#DDDBDB" }}>
          <ListCollapse name={namelist} open={open} />
          <Collapse component="li" in={open}>
            {list.map((text, index) => (
              <List key={text}>
                <Link
                  href={(
                    "/" +
                    text +
                    namelist.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                  ).toLocaleLowerCase()}
                  sx={{
                    color: "#DDDBDB",
                    textDecoration: "none",
                    py: 1,
                    pr: "35px",
                  }}
                >
                  {text}
                </Link>
              </List>
            ))}
          </Collapse>
        </List>
      </ListItemButton>
    </ListItem>
  );
};
export default MenuList;
