import Box from "@mui/material/Box";
import { useState } from "react";
import InputMask from "react-input-mask";
import ListSelect from "../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography } from "@mui/material";
import IconSalvar from "@mui/icons-material/FileOpenOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  URL_API,
  funcao_servidor,
  origem_servidor,
  perfil_servidor,
} from "../../../constants";

const FormularioServidor = () => {
  const [valuesInput, setValuesInput] = useState([]);
  const [valueperfil, setValuesPerfil] = useState();

  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/novoservidor`, {
        token: localStorage.token,

        dados_servidor: valuesInput,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (response.data.status == "success") {
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Novo Servidor
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Origem"
              list={origem_servidor}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.origem : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  origem: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Função"
              list={funcao_servidor}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.funcao : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  funcao: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Perfil*"
              list={perfil_servidor}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.perfil : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  perfil: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <ListSelect
              title="Situação *"
              list={["Ativo", "Inativo"]}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.situacao : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  situacao: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="matricula_servidor"
              name="matricula_servidor"
              label="Matrícula"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="nome_guerra"
              name="nome_guerra"
              label="Nome de Guerra"
              variant="outlined"
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="codigo_anac"
              name="codigo_anac"
              type="number"
              label="Código ANAC"
              variant="outlined"
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="horas_iniciais"
              name="horas_iniciais"
              label="Horas Iniciais"
              type="number"
              variant="outlined"
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="nome_completo"
              name="nome_completo"
              type="text"
              label="Nome Completo"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask mask="99/99/9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="data_nascimento"
                  name="data_nascimento"
                  label="Data de Nascimento"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask mask="999.999.999-99" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <TextField
              id="rg"
              name="rg"
              label="Nº RG civil"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 2 }}>
            <InputMask mask="(99)9 9999-9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="telefone"
                  name="telefone"
                  label="Telefone celular"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} md={6} sx={{ px: 2, my: 2 }}>
            <TextField
              id="endereco"
              name="endereco"
              label="Endereço"
              variant="outlined"
              required
              fullWidth
              onChange={handleaddValues}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                  mt: 2,
                }}
              >
                <IconSalvar sx={{ mx: 1 }} />
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </form>
  );
};
export default FormularioServidor;
