import Box from "@mui/material/Box";
import InputMask from "react-input-mask";
import ListSelect from "../../ComponentsFormulario/ListSelect";
import { Grid, TextField, Button, Typography } from "@mui/material";
import SalveIcon from "@mui/icons-material/FileOpenOutlined";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_API } from "../../../../constants";

const NovaAeronave = () => {
  const [valuesInput, setValuesInput] = useState([]);
  const handleaddValues = (value) => {
    setValuesInput((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${URL_API}/novaaeronave`, {
        token: localStorage.token,
        dados_aeronave: valuesInput,
        id_creator: localStorage.id,
      })
      .then((response) => {
        toast(response.data.message, {
          type: response.data.status,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (response.data.status === "success") {
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          background: "white",
          p: 2,
          m: 3,
          borderRadius: "10px",
          boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Typography
          sx={{ m: 2, color: "#072650", fontWeight: "700" }}
          variant="h5"
        >
          Nova Aeronave
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Tipo Uso *"
              list={["Aeromédico/Bombeiro", "Policial", "VIP"]}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.tipo_uso : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  tipo_uso: valor,
                }))
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2 }}>
            <ListSelect
              title="Situação"
              list={["Ativa", "Inativa"]}
              valor={
                Object.keys(valuesInput).length > 0 ? valuesInput.situacao : ""
              }
              handleChange={(valor) =>
                setValuesInput((prevValues) => ({
                  ...prevValues,
                  situacao: valor,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <InputMask mask="aa-aaa" onChange={handleaddValues}>
              {() => (
                <TextField
                  fullWidth
                  id="prefixo"
                  name="prefixo"
                  label="Prefixo"
                  variant="outlined"
                  required
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="apelido"
              name="apelido"
              label="Apelido"
              variant="outlined"
              onChange={handleaddValues}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="fabricante"
              name="fabricante"
              label="Fabricante"
              variant="outlined"
              onChange={handleaddValues}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="modelo"
              name="modelo"
              label="Modelo"
              variant="outlined"
              onChange={handleaddValues}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="numero_serie"
              name="numero_serie"
              label="Nº série"
              variant="outlined"
              onChange={handleaddValues}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <InputMask mask="9999" onChange={handleaddValues}>
              {() => (
                <TextField
                  id="ano_fabricacao"
                  name="ano_fabricacao"
                  label="Ano Fabricação"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="horas_iniciais"
              name="horas_iniciais"
              label="Horas Inciais"
              variant="outlined"
              fullWidth
              required
              onChange={handleaddValues}
            />
          </Grid>

          <Grid item xs={12} md={3} sx={{ px: 2, my: 3 }}>
            <TextField
              id="horas_mes"
              name="horas_mes"
              label="Horas Mês"
              variant="outlined"
              onChange={handleaddValues}
              fullWidth
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#0D6EFD",
                  borderRadius: "10px",
                  width: "100%",
                  marginTop: "25px",
                  mx: 2,
                }}
              >
                <SalveIcon sx={{ mx: 1 }} />
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </form>
  );
};
export default NovaAeronave;
