import { Grid, Button, Typography, Link } from "@mui/material";
const DadosVitima = (props) => {
  return (
    <>
      {props.dados.map((item) => (
        <Grid item xs={12} md={12} sx={{ px: 2 }}>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "700",
              display: "block",
              background: "#f5f5f5",
              p: 1,
            }}
            variant="p"
          >
            Dados da Vitima
          </Typography>
          <Typography
            sx={{
              m: 2,
              color: "#072650",
              fontWeight: "0",
              display: "block",
              p: 1,
            }}
            variant="p"
          >
            <Typography sx={{ m: 1 }}> Nome: {item.nome_vitima}</Typography>
            <Typography sx={{ m: 1 }}> Idade:{item.idade_vitima}</Typography>
            <Typography sx={{ m: 1 }}>
              CPF:
              {item.cpf_vitima}
            </Typography>
            <Typography sx={{ m: 1 }}>
              Sexo:
              {item.sexo_vitima}
            </Typography>
          </Typography>
        </Grid>
      ))}
    </>
  );
};
export default DadosVitima;
