import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { Grid, Button, Typography, Link } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import OcorrenciaUnica from "../Relatorio/OcorrenciaUnica";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DadosVitima from "./DadosVitima";
import DadosPoliciais from "./DadosPoliciais";
import { URL_API } from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
const VisualizarOcorrencia = () => {
  const { id } = useParams();
  const [ocorrencia, setOcorrencia] = useState([]);
  const [operadores, setOperadores] = useState(["Sem Operadores"]);

  const new_data = (data) => {
    const data_array = data.split("-");
    const new_data = data_array[2] + "/" + data_array[1] + "/" + data_array[0];
    return new_data;
  };
  const new_horas = (hora) => {
    const hora_array = hora.split(":");
    const new_hora = hora_array[0] + "h " + hora_array[1] + "min";
    return new_hora;
  };

  useEffect(() => {
    axios
      .post(`${URL_API}/pesquisarocorrencia`, {
        id: id,
      })
      .then(function (response) {
        setOperadores(response.data[0].servidores);
        setOcorrencia(response.data);
      })
      .catch(function (error) {
        toast("Erro ao conectar com o Servidor", {
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);

  return (
    <Box
      sx={{
        background: "white",
        p: 2,
        m: 3,
        borderRadius: "10px",
        boxShadow: "10px 8px 25px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Typography
        sx={{ m: 2, color: "#072650", fontWeight: "700" }}
        variant="h5"
      >
        Ocorrência
      </Typography>
      <Grid
        container
        sx={{
          textAlign: "right",
          justifyContent: "end",
        }}
      >
        <PDFDownloadLink
          document={<OcorrenciaUnica dados={ocorrencia} />}
          fileName="Ocorrência.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <CircularProgress />
            ) : (
              <Button variant="outlined" sx={{ mx: 2 }}>
                <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
              </Button>
            )
          }
        </PDFDownloadLink>
      </Grid>

      {ocorrencia.map((item) => (
        <Grid container>
          <Grid item xs={12} md={6} sx={{ px: 2 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Aeronave
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.aeronave.apelido_aeronave}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Base
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.base_ciopaer}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Comandante
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.comandante.nome_completo_servidor}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Copiloto
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.copiloto.nome_completo_servidor}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Cidade da Ocorrência
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.cidade_ocorrencia}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: 2 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Data da Ocorrência
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {new_data(item.data_ocorrencia)}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Horímetro Inicial da Ocorrência
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.horimetro_inicial_ocorrencia}
            </Typography>

            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Horímetro Final da Ocorrência
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.horimetro_final_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Duração da Ocorrência - Horímetro
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.duracao_ocorrencia_horimetro}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Duração da Ocorrência - Horas e Minutos
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.duracao_ocorrencia}
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Tipo e Natureza da Ocorrência
            </Typography>
            <Typography
              sx={{ mx: 2, color: "#072650", fontWeight: "0", p: 1 }}
              variant="p"
            >
              {item.tipo_ocorrencia} - {item.natureza_ocorrencia}
            </Typography>
          </Grid>

          {Object.is(item.tipo_ocorrencia, "Resgate") ? (
            item.hasOwnProperty("dados_da_ocorrencia") ? (
              <DadosVitima dados={item.dados_da_ocorrencia} />
            ) : (
              <Grid item xs={12} md={12} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    m: 2,
                    color: "#072650",
                    fontWeight: "700",
                    display: "block",
                    background: "#e0e0e0",
                    borderRadius: "5px",
                    p: 1,
                  }}
                  variant="p"
                >
                  Dados da Vitima
                </Typography>
                <Typography
                  sx={{
                    m: 2,
                    color: "#072650",
                    fontWeight: "0",
                    display: "block",
                    p: 1,
                  }}
                  variant="p"
                >
                  Não se Aplica
                </Typography>
              </Grid>
            )
          ) : (
            ""
          )}

          {Object.is(item.tipo_ocorrencia, "Policial") ? (
             item.hasOwnProperty("dados_da_ocorrencia") ? (
              <DadosPoliciais dados={item.dados_da_ocorrencia} />
            ) : (
              <Grid item xs={12} md={12} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    m: 2,
                    color: "#072650",
                    fontWeight: "700",
                    display: "block",
                    background: "#e0e0e0",
                    borderRadius: "5px",
                    p: 1,
                  }}
                  variant="p"
                >
                  Itens da Ocorrência
                </Typography>
                <Typography
                  sx={{
                    m: 2,
                    color: "#072650",
                    fontWeight: "0",
                    display: "block",
                    p: 1,
                  }}
                  variant="p"
                >
                  Não se Aplica
                </Typography>
              </Grid>
            )
          ) : (
            ""
          )}

          <Grid item xs={12} md={12} sx={{ px: 2 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Operadores
            </Typography>
            {(item.hasOwnProperty("operadores_ocorrencia")
              ? item.operadores_ocorrencia
              : operadores
            ).map((dados) => (
              <>
                <Typography
                  sx={{
                    mx: 2,
                    color: "#072650",
                    fontWeight: "0",
                    p: 1,
                    display: "block",
                  }}
                  variant="p"
                >
                  {dados.nome_completo_servidor} -{dados.funcao_servidor}
                </Typography>
              </>
            ))}
          </Grid>

          <Grid item xs={12} md={12} sx={{ px: 2 }}>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "700",
                display: "block",
                background: "#e0e0e0",
                borderRadius: "5px",
                p: 1,
              }}
              variant="p"
            >
              Histórico
            </Typography>
            <Typography
              sx={{
                m: 2,
                color: "#072650",
                fontWeight: "0",
                display: "block",
                p: 1,
              }}
              variant="p"
            >
              {item.historico_ocorrencia}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Grid item xs={12} sm={12} md={1} sx={{ px: 2, my: 2 }}>
          <Link href="/pesquisarocorrencia" sx={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "gray",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <ReplyOutlinedIcon sx={{ mr: 1 }} />
              Voltar
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VisualizarOcorrencia;
