export const NODE_ENV = process.env.NODE_ENV || "development";

export const URL_API =
  NODE_ENV === "production"
    ? "https://api.harpia.sejusp.acre.dev.br"
    : "http://localhost:5002";

//TIPO OCORRÊNCIA
export const funcao_servidor = [
  "Aux Mêcanico",
  "Diretor",
  "OAT",
  "OSM/Enfermeiro",
  "OSM/Médico",
  "PIC Helicóptero",
  "SIC Helicóptero",
  "PIC Avião",
  "SIC Avião",
  "Serviço Administrativo",
  "TASA/ Apoio Solo",
];
export const origem_servidor = ["Ciopaer", "Externo"];
export const perfil_servidor = ["Administrador", "Usuário"];
export const tipo_ocorrencia_natureza = {
  Resgate: [
    "Resgate Aeromédico",
    "Transporte Inter-hospitalar",
    "Salvamento Aquático",
    "Salvamento Veicular",
    "Busca e Salvamento ",
    "Transporte de órgão",
    "Resgate Indígena",
  ],
  Policial: ["Operação", "Ocorrência em andamento", "Polícia Militar", "Polícia Civil"],
  Fiscalização: ["Defesa Civil", "Ambiental"],
  VIP: ["Autoridades"],
  Instrução: ["Ciopaer", "Externa"],
  Manutenção: ["Giro de Manutenção"],
  Sejusp: ["Ato Institucional", "Expoacre"],
};
export function tipo_natureza_ocorrencia(tipo) {
  if (tipo_ocorrencia_natureza.hasOwnProperty(tipo)) {
    return tipo_ocorrencia_natureza[tipo];
  }
}

//NATUREZA DA OCORRÊNCIA
